import MessageOutlinedIcon from "@mui/icons-material/MessageOutlined";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";

export const libraryButtonsConfig = (t) => [
  {
    labelKey: t("pages.title.messages"),
    icon: (
      <MessageOutlinedIcon style={{ fontSize: "30px", marginRight: "10px" }} />
    ),
    path: "/messages",
    id: "messages",
  },
  {
    labelKey: t("pages.title.keywords"), 
    icon: (
      <DescriptionOutlinedIcon
        style={{ fontSize: "30px", marginRight: "10px" }}
      />
    ),
    path: "/keywords",
    id: "keywords",
  },
];