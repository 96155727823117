import React from "react";

import "./index.css";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const CheckProfileModal = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div className="connect-wraper">
      <button
        className="connect-account-btn"
        onClick={() => {
          navigate("/");
        }}
      >
        {t("pages.title.connect your account")}
      </button>
    </div>
  );
};

export default CheckProfileModal;
