import React, { useState, useContext } from "react";
import { CircularProgress } from "@mui/material";
import { updatelanguage } from "backendServices/ApiCalls";
import SweetAlert from "app/pages/components/mui/Alerts/SweetAlert";
import * as yup from "yup";
import { Form, Formik } from "formik";
import Div from "@jumbo/shared/Div/Div";
import { useTranslation } from "react-i18next";
import { CustomProvider } from "app/layouts/vertical-default/VerticalDefault";

const validationSchema = yup.object({
  language: yup.string().required("Language type is required"),
});

const PreferredLanguage = () => {
  const { t } = useTranslation();
  const { loginUserData, setloginUserData, loading } =
    useContext(CustomProvider);

  const [alertData, setalertData] = useState({
    show: false,
    message: "",
    variant: "",
  });

  const handleSubmit = (data, setSubmitting, resetForm) => {
    const params = {
      language: data.language,
    };
    updatelanguage(
      params,
      (response) => {
        if (response?.data?.status === "error") {
          setalertData({
            show: true,
            message: t("pages.title.Something went wrong please try again later"),
            variant: "error",
          });
          setSubmitting(false);
        } else if (response?.data?.status === "success") {
          setalertData({
            show: true,
            message: response?.data?.message,
            variant: "success",
          });
          setloginUserData((prevState) => ({
            ...prevState,
            language: data.language,
            language_status: 1,
          }));
          setSubmitting(false);
        } else {
          setalertData({
            show: true,
            message: t("pages.title.Something went wrong please try again later"),
            variant: "error",
          });
          setSubmitting(false);
        }
      },
      (error) => {
        setalertData({
          show: true,
          message: t("pages.title.An error occurred, please try again later"),
          variant: "error",
        });
        setSubmitting(false);
      }
    );
  };

  if (loading) {
    return (
      <Div
        sx={{
          display: "flex",
          minWidth: 0,
          alignItems: "center",
          alignContent: "center",
          height: "100%",
        }}
      >
        <CircularProgress sx={{ m: "-40px auto 0" }} />
      </Div>
    );
  }

  return (
    <>
      <Formik
        initialValues={{
          language: loginUserData?.language || "",
        }}
        validationSchema={validationSchema}
        onSubmit={(data, { setSubmitting, resetForm }) => {
          setSubmitting(true);
          handleSubmit(data, setSubmitting, resetForm);
        }}
      >
        {({ values, handleChange, handleBlur, isSubmitting }) => (
          <Form noValidate autoComplete="off">
            <div className="profile-language">
              <div className="profile-selct">
                <label className="profile-billing" htmlFor="language">
                  <span>{t("pages.title.language")}</span> <span>:</span>
                </label>
                <select
                  name="language"
                  id="language"
                  value={values.language}
                  onChange={handleChange}
                  onBlur={handleBlur}
                >
                  <option value="en">{t("pages.title.english")}</option>
                  <option value="fr">{t("pages.title.french")}</option>
                  <option value="gr">{t("pages.title.german")}</option>
                </select>
              </div>
              <button
                type="submit"
                className="profile-left-btn"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                disabled={isSubmitting}
              >
                <span>
                  {isSubmitting ? (
                    <>
                      <CircularProgress size={24} style={{ marginRight: 8 }} />
                    </>
                  ) : (
                    t("pages.title.update")
                  )}
                </span>
              </button>
            </div>
          </Form>
        )}
      </Formik>
      {alertData.show ? (
        <SweetAlert alertData={alertData} setalertData={setalertData} />
      ) : (
        ""
      )}
    </>
  );
};

export default PreferredLanguage;
