import React, { useContext, useEffect, useState, useRef } from "react";
import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogContent,
  DialogActions,
  IconButton,
  MenuItem,
  Checkbox,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import Div from "@jumbo/shared/Div/Div"; 
import { CircularProgress } from "@mui/material";
import SweetAlert from "app/pages/components/mui/Alerts/SweetAlert";
import { useTranslation } from "react-i18next";
import { CustomProvider } from "app/layouts/vertical-default/VerticalDefault";
import SyncIcon from "@mui/icons-material/Sync";
import SellIcon from '@mui/icons-material/Sell';
import CloseIcon from "@mui/icons-material/Close";
import Popover from '@mui/material/Popover';
// import "./Table.css";
import { Datatable2 } from "app/pages/components/mui/Datatable";
import TabMenu from "app/pages/components/mui/TabMenu/TabMenu";
// import { novaButtonsConfig } from "./TabData";
import { styled } from "@mui/system";
import { useModalState } from "app/hooks/use-modal-state";

import TopHeader from "app/pages/components/mui/TopHeader/TopHeader";
import { IoAdd } from "react-icons/io5";
import { getInstagramFollowers, fetchCrmGroups, tagInstaFollowers } from "backendServices/ApiCalls";
const InstaFollowers = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [dataLoading, setDataLoading] = useState(false);
  const [followers, setFollowers] = useState([]);
  const [alertData, setalertData] = React.useState({
    show: false,
    message: "",
    variant: "",
  }); 
  const [pagination, setPagination] = useState({ pageSize: 25, page: 1 });
  const [totalPages, setTotalPages] = useState();
  const [totalRows, setTotalRows] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [isTag, setIsTag] = useState(false);
  // const [tagData, setTagData] = useState({fbId:'', name: '',Tag:'',Tag_id:'', rgb:'',id:""});
  const [tagList, setTagList] = useState([])
  const [sortModel, setSortModel] = useState([]);


  const [selectedRows, setSelectedRows] = React.useState([]);
  const addSync = useModalState();
  const profileSync = useModalState();
  const unfrinedUser = useModalState();
  const whitelistModal = useModalState();
  const [selectedUsernames, setSelectedUsernames] = useState("");
  const [categoryFilter, setCategoryFilter] = useState(null);
  const [privacyFilter, setPrivacyFilter] = useState(null);
  const [syncedFilter, setSyncedFilter] = useState(null);
  const isInitialRender = useRef(true);
  const [anchorEl, setAnchorEl] = useState(null);
  // const [filterApplied, setFilterApplied] = useState(false);
  const [fetchFiltersData, setFetchFiltersData] = useState(false);
  const [appliedFilters, setAppliedFilters] = useState({});


  const [selectedTags, setSelectedTags] = useState([]);
  const [selectedPrimaryTag, setSelectedPrimaryTag] = useState(null);

  const open = Boolean(anchorEl);
  const filterPopupId = open ? 'simple-popover' : undefined;
  const [tagAssignLoading, setTagAssignLoading] = useState(false);
  const [tagRemoveLoading, setTagRemoveLoading] = useState(false);
  const handleFilterPopup = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const closeFilterPopup = () => {
    setAnchorEl(null);
  };

  let params = {};
  const FetchFollowers = () => {
    
    setDataLoading(true);
    let payload = {}
    payload.sorting = sortModel?.[0];
    payload.category = appliedFilters.category || "all";
    payload.privacy = appliedFilters.privacy || "all";
    payload.synced = appliedFilters.synced || "all";
    getInstagramFollowers(
      payload,
      (response) => {
        setLoading(false);
        setDataLoading(false);
        console.log(response)
        if (response?.data?.data) setFollowers(response?.data?.data ?? []);
        setTotalPages(response?.data?.totalPages);
        setCurrentPage(response?.data?.currentPage);
        setTotalRows(response?.data?.totalCount);
      },
      (error) => {
        setDataLoading(false);
        setLoading(false); 
      },
      pagination
    );
  };
  const { loginUserData } = useContext(CustomProvider);

  useEffect(() => {
    console.log('pagination.page', pagination.page);
    setCurrentPage(pagination.page);
    FetchFollowers();
  }, [pagination]);

  useEffect(() => {
    console.log('filter pagination.page', pagination.page);
    if (isInitialRender.current) {
      return false;
    }
    setPagination((data) => ({
      ...data,
      page: 1
    }));
    
  }, [fetchFiltersData]);

  useEffect(() => {
    if(sortModel?.length){
      FetchFollowers()
    }
  }, [sortModel])

  useEffect(() => {
    isInitialRender.current = false;
  }, []);


  const syncMembersInfo = () => {


    const usernames = selectedRows
    .map(id => {
      const match = followers.find(item => item.id === id);
      return match ? {id: match.id, user : match.insta_user_name} : null;
    })
    .filter(username => username !== null); // Filter out non-matches

    setSelectedUsernames(JSON.stringify(usernames));
    profileSync.onOpen()
  }

  // const handleCategoryFilter = (event) => {
  //   const selectedCategory = event.target.value;
  //   setCategoryFilter(selectedCategory);
  // }

  
  // const applyPrivacyFilter = (event) => {
  //   const selectedPrivacy = event.target.value;
  //   setPrivacyFilter(selectedPrivacy);
  // }

  
  // const applySyncingFilter = (event) => {
  //   const selectedProfiles = event.target.value;
  //   setSyncedFilter(selectedProfiles);
  // }

  const syncedFilterMapping = {
    "1": "Yes",
    "0": "No"
  };
  
  const applyFilters = () => {

    let filters = {}
    if(categoryFilter){
      filters.category = categoryFilter;
    }
    
    if(privacyFilter){
      filters.privacy = privacyFilter;
    }

    if(syncedFilter){
      filters.synced = syncedFilter;
    }

    setAppliedFilters(filters);

    // console.log(filters);
    // console.log(appliedFilters)
  //   setFilterApplied(true);
    setFetchFiltersData(value => !value);
    closeFilterPopup();
  }

  const handleFilterDelete = (filterKey) => {
    const newFilters = { ...appliedFilters };
    delete newFilters[filterKey];

    if(filterKey == "category"){
      setCategoryFilter(null);
    }
    if(filterKey == "privacy"){
      setPrivacyFilter(null);
    }
    if(filterKey == "synced"){
      setSyncedFilter(null);
    }

    setAppliedFilters(newFilters);
    setFetchFiltersData(value => !value);

    
  };

  const capitalizeFirstLetter = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const removeFilters = () => {
  //   //reset filter states
  //   setCategoryFilter("all");
  //   setPrivacyFilter("all");
  //   setSyncedFilter("all");

  //   setFilterApplied(false);
  //   setFetchFiltersData(value => !value);
  //   closeFilterPopup();
  }

  const fetchTagData = async () => {
    let params = {media: "Instagram"};

    fetchCrmGroups(
      params,
      (response) => {
        if (response?.data !== undefined) {
          setTagList(response.data)
        }
      },
      (error) => {
        console.error(error?.response?.data);
      }
    );
  };

  useEffect(() => {
    fetchTagData();
  }, []);

  const openTagListingPopup = () => {
    if (!selectedRows.length) {
      setalertData({
        show: true,
        message: 'Please select Follower(s) First',
        variant: "error",
      });
      return;
    }
    // setTagData({ fbId: true, name: "Bulk Tagging", isSingle: false })
    setSelectedTags([]);
    setSelectedPrimaryTag(null)
    setIsTag(true);
  }

  const handlePrimaryDropdownChange = (event) => {
    const newPrimaryTag = event.target.value;
    setSelectedPrimaryTag(newPrimaryTag);

    // // Sync with tags list
    // setSelectedTags((prevTags) => {
    //   // Remove the old primary tag if it exists
    //   const updatedTags = prevTags.filter((id) => id !== selectedPrimaryTag);
    //   // Add the new primary tag
    //   return [...updatedTags, newPrimaryTag];
    // });

    setSelectedTags((prevTags) => { 
      // Add the new primary tag only if it doesn't already exist
      if (!prevTags.includes(newPrimaryTag)) {
        return [...prevTags, newPrimaryTag];
      }
      return prevTags;
    });
  };


  const handleTagSelection = (tagId, isChecked) => {
    setSelectedTags((prevTags) => {
      if (isChecked) {
        // Add the tag to the list
        return [...prevTags, tagId];
      } else {
        // Remove the tag from the list
        return prevTags.filter((id) => id !== tagId);
      }
    });    
  };

  const assignTags = () => {

    if (!selectedTags.length) {
      setalertData({
        show: true,
        message: 'Please select tag first',
        variant: "error",
      });
      return;
    }

    if (!selectedPrimaryTag) {
      setalertData({
        show: true,
        message: 'Please select primary tag first',
        variant: "error",
      });
      return;
    }

    const tagUsers = followers
    .filter(follower => selectedRows.includes(follower.id))
    .map(({ insta_user_name, insta_full_name, profile_pic_url, eimu_id }) => ({
        insta_user_id: insta_user_name,
        insta_name: insta_full_name,
        profilePic: profile_pic_url,
        thread_id: eimu_id
    })); 

    const tagData = selectedTags.map((tagId) => ({
      tagId: tagId,
      stageId: ""
    })); 

    let params = {}
    params.type = "bulk-tag-assign";
    params.members = tagUsers;
    params.selected_tag_stage_ids = tagData;
    params.is_primary = selectedPrimaryTag;

    setTagAssignLoading(true);
    tagInstaFollowers(params,
      (response) => {
        setalertData({
          show: true,
          message: "Tag Assigned Successfully!",
          variant: "success",
        });
        setTagAssignLoading(false);
        setIsTag(false);
        setSelectedRows([]);
        FetchFollowers();
      },
      (error) => {
        setalertData({
          show: true,
          message: error?.response?.data?.message,
          variant: "error",
        });
        setTagAssignLoading(false);
      }
    );

  }

  const removeTags = () => {
    if (!selectedTags.length) {
      setalertData({
        show: true,
        message: 'Please select tag first',
        variant: "error",
      });
      return;
    }

    const tagUsers = followers
    .filter(follower => selectedRows.includes(follower.id))
    .map(({ insta_user_name, insta_full_name, profile_pic_url, eimu_id }) => ({
        insta_user_id: insta_user_name
    })); 

    const tagData = selectedTags.map((tagId) => ({
      tagId: tagId,
      stageId: ""
    })); 

    let params = {}
    params.type = "bulk-tag-remove";
    params.members = tagUsers;
    params.selected_tag_stage_ids = tagData;

    setTagRemoveLoading(true);
    tagInstaFollowers(params,
      (response) => {
        setalertData({
          show: true,
          message: "Tag Removed Successfully!",
          variant: "success",
        });
        setTagRemoveLoading(false);
        setIsTag(false);
        setSelectedRows([]);
        FetchFollowers();
      },
      (error) => {
        setalertData({
          show: true,
          message: error?.response?.data?.message,
          variant: "error",
        });
        setTagRemoveLoading(false);
      }
    );
  }

  

  if (loading) {
    return (
      <Div
        sx={{
          display: "flex",
          minWidth: 0,
          alignItems: "center",
          alignContent: "center",
          height: "100%",
        }}
      >
        <CircularProgress sx={{ m: "-40px auto 0" }} />
      </Div>
    );
  }

  const verifiedBadge = (<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none">
    <g clip-path="url(#clip0_3249_2165)">
    <path d="M7.99652 1.76247C8.22827 1.16419 8.9555 0.935843 9.48765 1.29427L11.2919 2.5095C11.6409 2.74457 12.0997 2.73601 12.4397 2.4881L14.1973 1.20646C14.7158 0.828448 15.451 1.02952 15.7049 1.61875L16.5657 3.6165C16.7322 4.00294 17.1228 4.2438 17.5429 4.21906L19.7144 4.09114C20.3549 4.05342 20.8647 4.62007 20.7598 5.25303L20.4038 7.39902C20.335 7.81414 20.5334 8.22794 20.9001 8.43423L22.7961 9.50065C23.3553 9.81518 23.4778 10.5675 23.0473 11.0432L21.5877 12.6561C21.3054 12.9681 21.2485 13.4235 21.4455 13.7953L22.464 15.7175C22.7644 16.2844 22.4607 16.9836 21.8413 17.151L19.7414 17.7188C19.3352 17.8286 19.0412 18.1809 19.0059 18.6002L18.8235 20.7679C18.7697 21.4072 18.1363 21.8312 17.5247 21.6372L15.4512 20.9795C15.0501 20.8523 14.6123 20.9898 14.3559 21.3234L13.0305 23.0483C12.6396 23.5571 11.8775 23.5713 11.4679 23.0775L10.0791 21.4032C9.81045 21.0793 9.36781 20.9582 8.97174 21.1003L6.92419 21.8348C6.32027 22.0515 5.67147 21.6514 5.59387 21.0145L5.33076 18.8552C5.27987 18.4375 4.97294 18.0963 4.56293 18.0017L2.4433 17.5127C1.81813 17.3684 1.48861 16.6811 1.76766 16.1034L2.71375 14.1446C2.89676 13.7657 2.823 13.3127 2.52922 13.0115L1.01049 11.4541C0.562541 10.9947 0.656935 10.2384 1.20404 9.90321L3.05894 8.76686C3.41775 8.54705 3.60057 8.12614 3.5163 7.71388L3.08064 5.58264C2.95215 4.95403 3.44048 4.36877 4.08193 4.3826L6.25674 4.42948C6.67743 4.43854 7.05879 4.18329 7.21078 3.79092L7.99652 1.76247Z" fill="#3692E8"/>
    <path d="M8 12.1895L10.5 15.1895L16.5 9.18945" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </g>
    <defs>
      <clipPath id="clip0_3249_2165">
        <rect width="24" height="24" fill="white"/>
      </clipPath>
    </defs>
  </svg>);

  const columns = [
    {
      field: "insta_full_name",
      headerName: t("pages.title.Member"),
      dataGeneratorUniquenessEnabled: true,
      headerClassName: "nova-name-header",
      width: 300,
      disableColumnMenu: true,
      renderCell: (params) => (
        <div
          className="nova-name-custom-div"
          style={{
            display: "flex",
            alignItems: "center",
            border: "1px solid #E0E1E3",
            color: "#170F49",
            padding: "4px",
            borderRadius: "30px",
          }}
        >
          <img
            src={params.row.profile_pic_url}
            alt={params.row.insta_user_name}
            style={{
              width: "36px",
              height: "36px",
              borderRadius: "50%",
              marginRight: "8px",
            }}
          />
          <span className="nova-name-custom-span">{params.row.insta_full_name}</span>
          <IconButton
            className="nova-ig-icon"
            target="_blank"
            href={"https://www.instagram.com/"+params.row.insta_user_name}
            style={{paddingTop:"0px", paddingBottom:"0px"}}
          >

            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M12 5.75806C8.61291 5.75806 5.80646 8.51612 5.80646 11.9516C5.80646 15.3871 8.56452 18.1452 12 18.1452C15.4355 18.1452 18.1936 15.3387 18.1936 11.9516C18.1936 8.56451 15.3871 5.75806 12 5.75806ZM12 15.9193C9.82259 15.9193 8.03226 14.129 8.03226 11.9516C8.03226 9.77419 9.82259 7.98386 12 7.98386C14.1774 7.98386 15.9677 9.77419 15.9677 11.9516C15.9677 14.129 14.1774 15.9193 12 15.9193Z" fill="#2C73FF"></path>
              <path d="M18.4355 7.01617C19.2104 7.01617 19.8387 6.38792 19.8387 5.61294C19.8387 4.83796 19.2104 4.20972 18.4355 4.20972C17.6605 4.20972 17.0322 4.83796 17.0322 5.61294C17.0322 6.38792 17.6605 7.01617 18.4355 7.01617Z" fill="#2C73FF"></path>
              <path d="M22.0645 1.98387C20.8065 0.677419 19.0161 0 16.9839 0H7.01613C2.80645 0 0 2.80645 0 7.01613V16.9355C0 19.0161 0.677419 20.8065 2.03226 22.1129C3.33871 23.371 5.08065 24 7.06452 24H16.9355C19.0161 24 20.7581 23.3226 22.0161 22.1129C23.3226 20.8548 24 19.0645 24 16.9839V7.01613C24 4.98387 23.3226 3.24194 22.0645 1.98387ZM21.871 16.9839C21.871 18.4839 21.3387 19.6935 20.4677 20.5161C19.5968 21.3387 18.3871 21.7742 16.9355 21.7742H7.06452C5.6129 21.7742 4.40323 21.3387 3.53226 20.5161C2.66129 19.6452 2.22581 18.4355 2.22581 16.9355V7.01613C2.22581 5.56452 2.66129 4.35484 3.53226 3.48387C4.35484 2.66129 5.6129 2.22581 7.06452 2.22581H17.0323C18.4839 2.22581 19.6935 2.66129 20.5645 3.53226C21.3871 4.40323 21.871 5.6129 21.871 7.01613V16.9839Z" fill="#2C73FF"></path>
            </svg>
            {/* <svg
              width="36"
              height="36"
              viewBox="0 0 36 36"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                cx="18"
                cy="18"
                r="17.5"
                fill="#2C73FF"
                fillOpacity="0.1"
                stroke="#E0E1E3"
              />
              <path
                d="M19.6667 19.075H22.0476L23 14.875H19.6667V12.775C19.6667 11.6935 19.6667 10.675 21.5714 10.675H23V7.147C22.6895 7.10185 21.5171 7 20.279 7C17.6933 7 15.8571 8.73985 15.8571 11.935V14.875H13V19.075H15.8571V28H19.6667V19.075Z"
                fill="#2C73FF"
              />
            </svg> */}
          </IconButton>
        </div>
      ),
    },
    {
      field: "insta_user_name",
      headerName: "Username",
      dataGeneratorUniquenessEnabled: true,
      width: 200,
      headerClassName: "nova-name-header",
      disableColumnMenu: true,
      renderCell: (params) => (
        <div className="nova-mutual-custom-div">
          {params.row.insta_user_name}
        </div>
      ),
    },
    {
      field: "instataggeduser",
      headerName: "Tag",
      dataGeneratorUniquenessEnabled: true,
      width: 200,
      headerClassName: "nova-name-header",
      disableColumnMenu: true,
      renderCell: (params) => (
        <div className="nova-mutual-custom-div">
          {(params.row.instataggeduser && params.row.instataggeduser.tag && params.row.instataggeduser.tag.name) ? 
          <Chip label={params.row.instataggeduser.tag.name} style={{ backgroundColor: params.row.instataggeduser.tag.custom_color || "red" }} className="prospect-chip" variant="outlined" /> : "" }
        </div>
      ),
    },
    {
      field: "is_private",
      headerName: "Private",
      dataGeneratorUniquenessEnabled: true,
      width: 100,
      headerClassName: "nova-name-header",
      disableColumnMenu: true,
      renderCell: (params) => (
        <div className="nova-mutual-custom-div" style={{marginLeft: "calc(50% - 28px)"}}>
          {params.row.is_private === null ? " " : params.row.is_private === "1" ? "Yes" : "No"}
        </div>
      ),
    },
    {
      field: "followers",
      headerName: "Followers",
      dataGeneratorUniquenessEnabled: true,
      width: 120,
      headerClassName: "nova-name-header",
      disableColumnMenu: true,
      renderCell: (params) => (
        <div className="nova-mutual-custom-div" style={{marginLeft: "calc(50% - 28px)"}}>
          {params.row.followers ? params.row.followers : " " }
        </div>
      ),
    },
    {
      field: "followings",
      headerName: "Followings",
      dataGeneratorUniquenessEnabled: true,
      width: 130,
      headerClassName: "nova-name-header",
      disableColumnMenu: true,
      renderCell: (params) => (
        <div className="nova-mutual-custom-div" style={{marginLeft: "calc(50% - 28px)"}}>
          {params.row.followings ? params.row.followings : " " }
        </div>
      ),
    },
    {
      field: "is_verified",
      headerName: "Verified",
      dataGeneratorUniquenessEnabled: true,
      width: 110,
      headerClassName: "nova-name-header",
       disableColumnMenu: true,
      renderCell: (params) => (
        <div className="nova-mutual-custom-div" style={{marginLeft: "calc(50% - 28px)"}}>
          {params.row.is_verified == "1" ? verifiedBadge : "" }
        </div>
      ),
    },
    {
      field: "account_type",
      headerName: "Account Type",
      dataGeneratorUniquenessEnabled: true,
      width: 150,
      headerClassName: "nova-name-header",
      disableColumnMenu: true,
      renderCell: (params) => (
        <div className="nova-mutual-custom-div capitalize-str" style={{textAlign: "center"}}>
          {params.row.account_type && <span style={{textTransform:"capitalize"}}> 
            {params.row.account_type}
          </span>}
        </div>
      ),
    },
    {
      field: "business_email",
      headerName: "Email",
      dataGeneratorUniquenessEnabled: true,
      width: 120,
      headerClassName: "nova-name-header",
      disableColumnMenu: true,
      renderCell: (params) => (
        <div className="nova-mutual-custom-div" title={params.row.business_email}>
          {params.row.business_email ? params.row.business_email : " " }
        </div>
      ),
    },
    {
      field: "business_phone_number",
      headerName: "Phone",
      dataGeneratorUniquenessEnabled: true,
      width: 120,
      headerClassName: "nova-name-header",
      disableColumnMenu: true,
      renderCell: (params) => (
        <div className="nova-mutual-custom-div">
          {params.row.business_phone_number ? params.row.business_phone_number : " " }
        </div>
      ),
    },
    {
      field: "biography",
      headerName: "Biography",
      dataGeneratorUniquenessEnabled: true,
      width: 300,
      headerClassName: "nova-name-header",
      disableColumnMenu: true,
      renderCell: (params) => (
        <div className="nova-mutual-custom-div text-eclipse3" title={params.row.biography}>
          {params.row.biography ? params.row.biography : " "}
        </div>
      ),
    },
    {
      field: "bio_links",
      headerName: "Bio Links",
      dataGeneratorUniquenessEnabled: true,
      width: 180,
      headerClassName: "nova-name-header",
      disableColumnMenu: true,
      renderCell: (params) => (
        <div className="nova-mutual-custom-div">
          <ul style={{listStyleType: "disc", paddingLeft: "10px"}}>
          {(params.row.bio_links && params.row.bio_links !== "null") 
          ? JSON.parse(params.row.bio_links).map((link, index) => (
                <li className="biolink-li" style={{listStyleType: "disc"}}>
                  <a href={link.url} key={index} title={link.url} target="_blank">{link.url}</a> 
                </li>
            ))
          : " "}
          </ul>
        </div>
      ),
    },
    {
      field: "followed_by_viewer",
      headerName: "Followed by Me",
      dataGeneratorUniquenessEnabled: true,
      width: 160,
      headerClassName: "nova-name-header",
      disableColumnMenu: true,
      renderCell: (params) => (
        <div className="nova-mutual-custom-div" style={{marginLeft: "calc(50% - 28px)"}}>
          {params.row.followed_by_viewer == "1" ? "Yes" : "No" }
        </div>
      ),
    }
  ];


  // translation in header pending
  // translatation in sidebar menu 
  return (
    <>
      <TopHeader
        title="Instagram Followers"
        url={t("pages.title.friend_video_url")}
      />
      {alertData.show && (
        <SweetAlert alertData={alertData} setalertData={setalertData} />
      )}
      {/* <TabMenu buttonsConfig={novaButtonsConfig} /> */}
      <div className="nova-container">
        <div className="nova-box nova-box-only nova--friends">

          <Box style={{marginBottom: "-35px", marginLeft: "270px", zIndex:"999", position: "relative", display: "flex", alignItems: "center"}}>
            <Button aria-describedby={filterPopupId} variant="contained" onClick={handleFilterPopup}>
              Filter {Object.keys(appliedFilters).length > 0 && <span style={{marginLeft: "5px"}}> ({Object.keys(appliedFilters).length})</span>}
            </Button>
            <Popover
              id={filterPopupId}
              open={open}
              anchorEl={anchorEl}
              onClose={closeFilterPopup}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
            >
              <Box sx={{padding: "15px", width: "230px"}}>
                <Box sx={{marginBottom:"15px"}}>
                  <div style={{fontWeight:"600"}}>Select Category</div>
                  <div>
                    <Select
                      labelId="category-filter-label"
                      id="category_filter"
                      value={categoryFilter}
                      onChange={(e) => setCategoryFilter(e.target.value)} // Inline update
                      displayEmpty 
                      sx={{ width: "100%", display: "flex", alignItems: "center" }}
                    >
                      <MenuItem value="business">Business</MenuItem>
                      <MenuItem value="professional">Professional</MenuItem>
                      <MenuItem value="personal">Personal</MenuItem>
                    </Select>
                  </div>
                </Box>

                <Box sx={{marginBottom:"15px"}}>
                  <div style={{fontWeight:"600"}}>Select Privacy</div>
                  <div>
                    <Select
                      labelId="privacy-filter-label"
                      id="privacy_filter"
                      value={privacyFilter}
                      onChange={(e) => setPrivacyFilter(e.target.value)} // Inline update
                      displayEmpty
                      sx={{ width: "100%", display: "flex", alignItems: "center" }}
                    >
                      <MenuItem value="private">Private</MenuItem>
                      <MenuItem value="public">Public</MenuItem>
                    </Select>
                  </div>
                </Box>

                <Box sx={{marginBottom:"15px"}}>
                  <div style={{fontWeight:"600"}}>Select Profiles</div>
                  <div>
                    <Select
                      labelId="synced-filter-label"
                      id="synced_filter"
                      value={syncedFilter}
                      onChange={(e) => setSyncedFilter(e.target.value)} // Inline update
                      displayEmpty
                      sx={{ width: "100%", display: "flex", alignItems: "center" }}
                    >
                      <MenuItem value="1">Synced</MenuItem>
                      <MenuItem value="0">Not Synced</MenuItem>
                    </Select>
                  </div>
                </Box>

                <Box sx={{textAlign:"right"}} gap={"50px"} display={'flex'}>
                  <Button variant="contained" onClick={applyFilters}>
                    Apply
                  </Button>
                  {/* {filterApplied && <Button variant="outlined" onClick={removeFilters}>
                    Reset
                  </Button>} */}
                </Box>
              </Box>
            </Popover>
            
            {<Box>
              {/* <Chip label="Category: All" variant="outlined" color="error" style={{marginLeft:"10px"}} onDelete={handleFilterPopup} />
              <Chip label="Privacy: All" variant="outlined" color="error" style={{marginLeft:"10px"}} onDelete={handleFilterPopup} />
              <Chip label="Profiles: All" variant="outlined" color="error" style={{marginLeft:"10px"}} onDelete={handleFilterPopup} /> */}

              {Object.entries(appliedFilters).map(([key, value]) => {  
                // Handle the specific case for syncedFilter and display the mapped value
                let displayValue = value;
                if (key === "synced") {
                  displayValue = syncedFilterMapping[value] || "All"; // Use mapping or default "All"
                }

                return (
                  <Chip
                    key={key}
                    label={`${capitalizeFirstLetter(key)}: ${capitalizeFirstLetter(displayValue) || "All"}`} // Display the mapped value or "All"
                    // variant="outlined"
                    color="success"
                    style={{ marginLeft: "10px" }}
                    onDelete={() => handleFilterDelete(key)} // Deletes the chip on click
                  />
                );
              })}
            </Box>}
          </Box>
          <Box className="nova-btn-card btn-group-friends-list" sx={{zIndex: 999}}>

            {/* <select id="category_filter" onChange={applyCategoryFilter}>
              <option value="all">All Categories</option>
              <option value="business">Business</option>
              <option value="professional">Professional</option>
              <option value="personal">Personal</option>
            </select>

            <select id="privacy_filter" onChange={applyPrivacyFilter}>
              <option value="all">All Accounts</option>
              <option value="private">Private</option>
              <option value="public">Public</option>
            </select>

            <select id="syncing_filter" onChange={applySyncingFilter}>
              <option value="all">All Profiles</option>
              <option value="1">Synced</option>
              <option value="0">Not Synced</option>
            </select> */}
  
            { selectedRows.length > 0 && <Button
              className="nova-blue-btn"
              variant="contained"
              startIcon={<SellIcon />}
              onClick={openTagListingPopup}
            >
              {/* {t("pages.title.asyncnovadata")} */}
              Add to Group
            </Button>}

            { selectedRows.length > 0 && <Button
              className="nova-blue-btn"
              variant="contained"
              startIcon={<SyncIcon />}
              onClick={syncMembersInfo}
              style={{minWidth:"195px"}}
            >
              {/* {t("pages.title.asyncnovadata")} */}
              Sync Members Info
            </Button> }

            <Button
              className="nova-blue-btn"
              variant="contained"
              startIcon={<SyncIcon />}
              onClick={() => addSync.onOpen()}
            >
              {t("pages.title.asyncnovadata")}
            </Button>
          </Box>

          

          <Datatable2
            rows={followers}
            columns={columns}
            loading={dataLoading}
            checkboxSelection
            onRowSelectionModelChange={(ids) => {
              setSelectedRows(ids);
            }}
            rowSelectionModel={selectedRows}
            onPaginationModelChange={(pagedata) =>
              setPagination((data) => ({
                ...data,
                pageSize: pagedata.pageSize,
              }))
            }
            // rowCount={totalRows}
            totalPages={totalPages}
            pageSize={pagination.pageSize}
            currentPage={currentPage}
            disableRowSelectionOnClick={true}
            onSortModelChange={(newSortModel) => setSortModel(newSortModel)}
            onPageTrigger={(pagedata) => {
              if (parseInt(pagedata)) {
                setPagination((data) => ({
                  ...data,
                  page: parseInt(pagedata) || data.page,
                }));
              }
            }}
           
            className="nova-table-ctm"
            // className="nova-table-ctm nova-table-activated"
          />
          {/* <div className="nova-table-activated-btn"><button>Unlock</button></div> */}
        </div>
      </div>

      {/* start sync model  */} 

      <BootstrapDialog
        className="nova-warning-dialog"
        open={addSync.isOpen}
        onClose={(event, reason) => {
          if (reason !== "backdropClick") {
            addSync.onClose();
          }
        }}
        sx={{
          ".MuiDialog-container": {
            mt: "-80px",
          },
          "& .MuiDialog-paper": {
            width: "80%",
            marginTop: "174px",
            borderRadius: "12px",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#F4F1FA",
            color: "#2C73FF",
            p: 2,
          }}
        >
          <Typography
            variant="h6"
            sx={{
              fontSize: "20px",
              textAlign: "center",
              flexGrow: 1,
              color: "#2C73FF",
            }}
            className="insta-popup-progress"
          >
            Warning
          </Typography>
        </Box>

        <DialogContent className="stage-popup-main" sx={{ padding: "0px" }}>
          <Box className="nova-warning-box insta-sync-warning">
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "20px",
                padding: "20px",
              }}
            >
              <svg
                width="18"
                height="17"
                viewBox="0 0 18 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M2.26444 16.0377H15.7356C15.9543 16.0377 16.1694 15.9809 16.3597 15.8729C16.5499 15.7649 16.7089 15.6093 16.8211 15.4215C16.9333 15.2336 16.9948 15.0199 16.9997 14.8012C17.0046 14.5824 16.9526 14.3662 16.8489 14.1735L10.1137 1.66514C9.63589 0.778287 8.36411 0.778287 7.8863 1.66514L1.15114 14.1735C1.04742 14.3662 0.995449 14.5824 1.00031 14.8012C1.00518 15.0199 1.0667 15.2336 1.17889 15.4215C1.29107 15.6093 1.45006 15.7649 1.64034 15.8729C1.83061 15.9809 2.04565 16.0377 2.26444 16.0377Z"
                  stroke="#FF0202"
                  strokeOpacity="0.7"
                  strokeWidth="1.25"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M8.77164 6.12383L8.99849 10.9454L9.22494 6.12581C9.22634 6.095 9.22144 6.06423 9.21054 6.03537C9.19965 6.00652 9.18298 5.98019 9.16156 5.958C9.14015 5.9358 9.11443 5.91821 9.08598 5.90629C9.05754 5.89437 9.02696 5.88838 8.99612 5.88868C8.96581 5.88898 8.93588 5.89534 8.90808 5.9074C8.88028 5.91946 8.85517 5.93696 8.83425 5.95888C8.81332 5.9808 8.797 6.00669 8.78625 6.03502C8.7755 6.06335 8.77053 6.09355 8.77164 6.12383Z"
                  stroke="#FF0202"
                  strokeOpacity="0.7"
                  strokeWidth="1.25"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M8.9994 14.1004C8.84307 14.1004 8.69025 14.054 8.56027 13.9672C8.43029 13.8803 8.32898 13.7569 8.26915 13.6124C8.20933 13.468 8.19367 13.3091 8.22417 13.1557C8.25467 13.0024 8.32995 12.8616 8.44049 12.751C8.55104 12.6405 8.69187 12.5652 8.8452 12.5347C8.99853 12.5042 9.15745 12.5199 9.30188 12.5797C9.44631 12.6395 9.56976 12.7408 9.65661 12.8708C9.74347 13.0008 9.78982 13.1536 9.78982 13.31C9.78982 13.5196 9.70655 13.7206 9.55831 13.8689C9.41008 14.0171 9.20904 14.1004 8.9994 14.1004Z"
                  fill="#FF0202"
                  fillOpacity="0.7"
                />
              </svg>
           
          
              <Typography sx={{ textAlign: "left" }}>
                Make sure you are logged in instagram.
              </Typography>
            </Box>
            <Box
              sx={
                
                {
                display: "flex",
                alignItems: "center",
                gap: "20px",
                padding: "20px",
              }}
            >
              <svg
                width="18"
                height="17"
                viewBox="0 0 18 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M2.26444 16.0377H15.7356C15.9543 16.0377 16.1694 15.9809 16.3597 15.8729C16.5499 15.7649 16.7089 15.6093 16.8211 15.4215C16.9333 15.2336 16.9948 15.0199 16.9997 14.8012C17.0046 14.5824 16.9526 14.3662 16.8489 14.1735L10.1137 1.66514C9.63589 0.778287 8.36411 0.778287 7.8863 1.66514L1.15114 14.1735C1.04742 14.3662 0.995449 14.5824 1.00031 14.8012C1.00518 15.0199 1.0667 15.2336 1.17889 15.4215C1.29107 15.6093 1.45006 15.7649 1.64034 15.8729C1.83061 15.9809 2.04565 16.0377 2.26444 16.0377Z"
                  stroke="#FF0202"
                  strokeOpacity="0.7"
                  strokeWidth="1.25"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M8.77164 6.12383L8.99849 10.9454L9.22494 6.12581C9.22634 6.095 9.22144 6.06423 9.21054 6.03537C9.19965 6.00652 9.18298 5.98019 9.16156 5.958C9.14015 5.9358 9.11443 5.91821 9.08598 5.90629C9.05754 5.89437 9.02696 5.88838 8.99612 5.88868C8.96581 5.88898 8.93588 5.89534 8.90808 5.9074C8.88028 5.91946 8.85517 5.93696 8.83425 5.95888C8.81332 5.9808 8.797 6.00669 8.78625 6.03502C8.7755 6.06335 8.77053 6.09355 8.77164 6.12383Z"
                  stroke="#FF0202"
                  strokeOpacity="0.7"
                  strokeWidth="1.25"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M8.9994 14.1004C8.84307 14.1004 8.69025 14.054 8.56027 13.9672C8.43029 13.8803 8.32898 13.7569 8.26915 13.6124C8.20933 13.468 8.19367 13.3091 8.22417 13.1557C8.25467 13.0024 8.32995 12.8616 8.44049 12.751C8.55104 12.6405 8.69187 12.5652 8.8452 12.5347C8.99853 12.5042 9.15745 12.5199 9.30188 12.5797C9.44631 12.6395 9.56976 12.7408 9.65661 12.8708C9.74347 13.0008 9.78982 13.1536 9.78982 13.31C9.78982 13.5196 9.70655 13.7206 9.55831 13.8689C9.41008 14.0171 9.20904 14.1004 8.9994 14.1004Z"
                  fill="#FF0202"
                  fillOpacity="0.7"
                />
              </svg>
              <Typography sx={{ textAlign: "left" }}>
                Your Computer and Internet connexion should stay ON during the
                full process
              </Typography>
            </Box>
          </Box>

          <Box className="nova-warning-box insta-sync-progress" sx={{display: "none"}}>
              
          </Box>
          <Box
            className="stage-btn-group"
            sx={{
              backgroundColor: "#F4F1FA",
              m: "-20px",
              pb: "15px",
              pt: "10px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                gap: 2,
              }}
            >
              
              {followers.length > 0 &&  <button
                variant="outlined"
                className="nova-btn-start1 green-pop-btn"
                id="sync_insta_followers_from_start"
              >
                Sync from start
              </button>}

              <button
                variant="outlined"
                className="nova-btn-start1 green-pop-btn"
                id="sync_insta_followers"
              >
                {(followers.length > 0) ? "Resume where you left" : "Start" }
              </button>
              <button
                className="nova-btn-cancel gray-pop-btn"
                onClick={addSync.onClose}
                id="cancel_insta_syncing"
              >
                {t("pages.title.cancel")}
              </button>
            </Box>
          </Box>
        </DialogContent>
      </BootstrapDialog>


      {/* Detail syncing */}

      <BootstrapDialog
        className="nova-warning-dialog insta-profile-sync-dialog"
        open={profileSync.isOpen}
        onClose={(event, reason) => {
          if (reason !== "backdropClick") {
            profileSync.onClose();
          }
        }}
        sx={{
          ".MuiDialog-container": {
            mt: "-80px",
          },
          "& .MuiDialog-paper": {
            width: "80%",
            marginTop: "174px",
            borderRadius: "12px",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#F4F1FA",
            color: "#2C73FF",
            p: 2,
          }}
        >
          <Typography
            variant="h6"
            sx={{
              fontSize: "20px",
              textAlign: "center",
              flexGrow: 1,
              color: "#2C73FF",
            }}
            className="insta-popup-progress"
          >
            Warning
          </Typography>
        </Box>

        <DialogContent className="stage-popup-main" sx={{ padding: "0px" }}>
          <Box className="nova-warning-box insta-sync-warning">
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "20px",
                padding: "20px",
              }}
            >
              <svg
                width="18"
                height="17"
                viewBox="0 0 18 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M2.26444 16.0377H15.7356C15.9543 16.0377 16.1694 15.9809 16.3597 15.8729C16.5499 15.7649 16.7089 15.6093 16.8211 15.4215C16.9333 15.2336 16.9948 15.0199 16.9997 14.8012C17.0046 14.5824 16.9526 14.3662 16.8489 14.1735L10.1137 1.66514C9.63589 0.778287 8.36411 0.778287 7.8863 1.66514L1.15114 14.1735C1.04742 14.3662 0.995449 14.5824 1.00031 14.8012C1.00518 15.0199 1.0667 15.2336 1.17889 15.4215C1.29107 15.6093 1.45006 15.7649 1.64034 15.8729C1.83061 15.9809 2.04565 16.0377 2.26444 16.0377Z"
                  stroke="#FF0202"
                  strokeOpacity="0.7"
                  strokeWidth="1.25"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M8.77164 6.12383L8.99849 10.9454L9.22494 6.12581C9.22634 6.095 9.22144 6.06423 9.21054 6.03537C9.19965 6.00652 9.18298 5.98019 9.16156 5.958C9.14015 5.9358 9.11443 5.91821 9.08598 5.90629C9.05754 5.89437 9.02696 5.88838 8.99612 5.88868C8.96581 5.88898 8.93588 5.89534 8.90808 5.9074C8.88028 5.91946 8.85517 5.93696 8.83425 5.95888C8.81332 5.9808 8.797 6.00669 8.78625 6.03502C8.7755 6.06335 8.77053 6.09355 8.77164 6.12383Z"
                  stroke="#FF0202"
                  strokeOpacity="0.7"
                  strokeWidth="1.25"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M8.9994 14.1004C8.84307 14.1004 8.69025 14.054 8.56027 13.9672C8.43029 13.8803 8.32898 13.7569 8.26915 13.6124C8.20933 13.468 8.19367 13.3091 8.22417 13.1557C8.25467 13.0024 8.32995 12.8616 8.44049 12.751C8.55104 12.6405 8.69187 12.5652 8.8452 12.5347C8.99853 12.5042 9.15745 12.5199 9.30188 12.5797C9.44631 12.6395 9.56976 12.7408 9.65661 12.8708C9.74347 13.0008 9.78982 13.1536 9.78982 13.31C9.78982 13.5196 9.70655 13.7206 9.55831 13.8689C9.41008 14.0171 9.20904 14.1004 8.9994 14.1004Z"
                  fill="#FF0202"
                  fillOpacity="0.7"
                />
              </svg>
           
          
              <Typography sx={{ textAlign: "left" }}>
                Make sure you are logged in instagram.
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "20px",
                padding: "20px",
              }}
            >
              <svg
                width="18"
                height="17"
                viewBox="0 0 18 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M2.26444 16.0377H15.7356C15.9543 16.0377 16.1694 15.9809 16.3597 15.8729C16.5499 15.7649 16.7089 15.6093 16.8211 15.4215C16.9333 15.2336 16.9948 15.0199 16.9997 14.8012C17.0046 14.5824 16.9526 14.3662 16.8489 14.1735L10.1137 1.66514C9.63589 0.778287 8.36411 0.778287 7.8863 1.66514L1.15114 14.1735C1.04742 14.3662 0.995449 14.5824 1.00031 14.8012C1.00518 15.0199 1.0667 15.2336 1.17889 15.4215C1.29107 15.6093 1.45006 15.7649 1.64034 15.8729C1.83061 15.9809 2.04565 16.0377 2.26444 16.0377Z"
                  stroke="#FF0202"
                  strokeOpacity="0.7"
                  strokeWidth="1.25"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M8.77164 6.12383L8.99849 10.9454L9.22494 6.12581C9.22634 6.095 9.22144 6.06423 9.21054 6.03537C9.19965 6.00652 9.18298 5.98019 9.16156 5.958C9.14015 5.9358 9.11443 5.91821 9.08598 5.90629C9.05754 5.89437 9.02696 5.88838 8.99612 5.88868C8.96581 5.88898 8.93588 5.89534 8.90808 5.9074C8.88028 5.91946 8.85517 5.93696 8.83425 5.95888C8.81332 5.9808 8.797 6.00669 8.78625 6.03502C8.7755 6.06335 8.77053 6.09355 8.77164 6.12383Z"
                  stroke="#FF0202"
                  strokeOpacity="0.7"
                  strokeWidth="1.25"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M8.9994 14.1004C8.84307 14.1004 8.69025 14.054 8.56027 13.9672C8.43029 13.8803 8.32898 13.7569 8.26915 13.6124C8.20933 13.468 8.19367 13.3091 8.22417 13.1557C8.25467 13.0024 8.32995 12.8616 8.44049 12.751C8.55104 12.6405 8.69187 12.5652 8.8452 12.5347C8.99853 12.5042 9.15745 12.5199 9.30188 12.5797C9.44631 12.6395 9.56976 12.7408 9.65661 12.8708C9.74347 13.0008 9.78982 13.1536 9.78982 13.31C9.78982 13.5196 9.70655 13.7206 9.55831 13.8689C9.41008 14.0171 9.20904 14.1004 8.9994 14.1004Z"
                  fill="#FF0202"
                  fillOpacity="0.7"
                />
              </svg>
              <Typography sx={{ textAlign: "left" }}>
                Your Computer and Internet connexion should stay ON during the
                full process
              </Typography>
            </Box>
          </Box>

          <Box className="nova-warning-box insta-sync-progress" sx={{display: "none"}}>
              
          </Box>
          <Box
            className="stage-btn-group"
            sx={{
              backgroundColor: "#F4F1FA",
              m: "-20px",
              pb: "15px",
              pt: "10px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                gap: 2,
              }}
            >
              
              <button
                variant="outlined"
                className="nova-btn-start1 green-pop-btn"
                id="sync_insta_profiles"
                data-profiles={selectedUsernames}
              >
                Start
              </button>
              <button
                className="nova-btn-cancel gray-pop-btn"
                onClick={profileSync.onClose}
                id="cancel_insta_profile_syncing"
              >
                {t("pages.title.cancel")}
              </button>
            </Box>
          </Box>
        </DialogContent>
      </BootstrapDialog>

      {/* Add to groups  */}

      <BootstrapDialog
        className="novafriend-popups-tag novafriend-popups"
        open={isTag}
        onClose={() => setIsTag(false)}
        sx={{
          ".MuiDialog-container": {
            mt: "-80px",
          },
          "& .MuiDialog-paper": {
            width: "80%",
            marginTop: "174px",
            borderRadius: "12px",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#F4F1FA",
            color: "#2C73FF",
            p: 2,
          }}
        >
          <Typography

            sx={{
              fontSize: "20px",
              textAlign: "left",
              flexGrow: 1,

            }}
            style={{ color: "#7452aa", display: "flex", justifyContent: "space-between" }}
          >
            <div style={{ fontSize: "16px", fontWeight: "bold" }}> Bulk Tagging</div><div className="bulk-popup-close" onClick={() => setIsTag(false)} style={{ fontSize: "20px", fontWeight: "bold", cursor: "pointer" }}>x</div>
          </Typography>
        </Box>
        <div style={{
          padding: "8px 20px", width: "100%", display: "flex",
          alignItems: "center",
          justifyContent: "space-between"
        }}>
          {/* Heading */}
          <Typography variant="h6" sx={{
            wordWrap: "break-word",
            whiteSpace: "normal",
          }}>
            Please Select:
          </Typography>

          {/* Dropdown */}
          <Select
            value={selectedPrimaryTag || ""}
            onChange={handlePrimaryDropdownChange}
            displayEmpty
            sx={{ width: "300px", display: "flex", alignItems: "center" }}
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: "400px", // Set the dropdown height to 400px
                },
              },
            }}
          >   
            <MenuItem value="" disabled>
              Select Primary Tag
            </MenuItem>
            {tagList.map((tag) => (
              <MenuItem
                key={tag.id}
                value={tag.id}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "8px", // Space between icon and text
                }}
              >
                {/* {selectedPrimaryTag === tag.id ? (
                  <BlueTickIcon />
                ) : (
                  <span style={{ width: "24px" }} /> // Empty placeholder to maintain layout consistency
                )} */}
                {tag.name}
              </MenuItem>
            ))}
          </Select>

        </div>

        <DialogContent className="stage-popup-main tag-pop" sx={{ padding: "0px", paddingBottom: "20px !important;" }}>
          <Box
            className="stage-popup-main-text"
            sx={{ display: "flex", flexDirection: "column", gap: 2, minHeight: 400, width: "100%" }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "20px",
                padding: "20px 0 20px 20px",
                width: "100%"
              }}
            >

              <Typography sx={{ textAlign: "center", width: "100%" }}>
                <Stack style={{ flexWrap: "wrap", gap: "10px", width: "100%" }} spacing={1}>
                  {tagList.length &&
                    tagList
                      .filter((item) => item?.stage?.length !== 0)
                      .map((tag) => {
                        const isSelected = selectedTags.includes(tag.id);

                        return (
                          <div
                            key={tag.id}
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "10px",
                              backgroundColor: tag.custom_color,
                              borderRadius: "5px",
                              textAlign: "left",
                              fontWeight: 600,
                              cursor: "pointer", // Add pointer cursor for better UX
                            }}
                            className="prospect-chip"
                            tag-id={tag.id}
                            tag-bg-color={tag.custom_color}
                            onClick={() => handleTagSelection(tag.id, !isSelected)}
                          >
                            {/* Checkbox for Selection */}
                            <Checkbox
                              checked={isSelected}
                              onChange={(e) => {
                                e.stopPropagation(); // Prevent the click event from bubbling up
                                handleTagSelection(tag.id, e.target.checked);
                              }}
                              sx={{
                                color: "white", // Default color when unchecked
                                "&.Mui-checked": {
                                  color: "white", // Color when checked
                                },
                                "& .MuiSvgIcon-root": {
                                  fontSize: "1.5rem", // Adjust size if needed
                                },
                              }}
                            />
                            {/* Tag Name */}
                            {tag.name}
                          </div>
                        );
                      })}
                </Stack>
              </Typography>

            </Box>
          </Box>
        </DialogContent>
        <DialogActions
          sx={{
            backgroundColor: "#F4F1FA",
            display: "flex",
            justifyContent: "space-between",
            padding: "12px 20px !important"
          }}
        >
          <Button
            variant="contained"
            color="primary"
            onClick={assignTags}
            disabled= {tagAssignLoading}
          >
            {tagAssignLoading ? "Adding" : "Add"}
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={removeTags}
            disabled= {tagRemoveLoading}
          >
            {tagRemoveLoading ? "Removing" : "Remove"}
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </>
  );
};

export default InstaFollowers;

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));
