import React, { useState, useEffect, useContext } from "react";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  styled,
} from "@mui/material";
import {
  updateProfilePassword,
  cabcelsubscription,
} from "backendServices/ApiCalls";
import SweetAlert from "app/pages/components/mui/Alerts/SweetAlert";
import * as yup from "yup";
import { Form, Formik } from "formik";
import Div from "@jumbo/shared/Div/Div";
import { useTranslation } from "react-i18next";
import { CustomProvider } from "app/layouts/vertical-default/VerticalDefault";
import { useNavigate } from "react-router-dom";
import useJumboAuth from "@jumbo/hooks/useJumboAuth";
import {
  Close as CloseIcon,
  Warning as WarningIcon,
} from "@mui/icons-material";
import { keyframes } from "@emotion/react";

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
`;

const AnimatedDialog = styled(Dialog)`
  animation: ${fadeIn} 0.3s ease-in-out;
`;

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const validationSchema = (t) =>
  yup.object({
    oldpass: yup.string().required(t("pages.title.Password is required")),
    newpass: yup
      .string()
      .required(t("pages.title.New password is required"))
      .min(8, t("pages.title.Password must be at least 8 characters long"))
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&()+^])[A-Za-z\d@$!%*?&()+^]+$/,
        t(
          "pages.title.Password must include at least one uppercase letter, one lowercase letter, one number, and one special character. Allowed characters are @$!%*?&()+^"
        )
      ),
    confirmpassword: yup
      .string()
      .oneOf([yup.ref("newpass"), null], t("pages.title.Passwords must match"))
      .required(t("pages.title.Confirm password is required")),
  });

const PasswordForm = () => {
  const { t } = useTranslation();
  const [alertData, setalertData] = useState({
    show: false,
    message: "",
    variant: "",
  });
  const [open, setOpen] = React.useState(false);
  const { loginUserData, setloginUserData } = useContext(CustomProvider);
  const navigate = useNavigate();
  const { setAuthToken } = useJumboAuth();

  useEffect(() => {
    if (alertData.show) {
      setTimeout(() => {
        setalertData((prevData) => ({
          ...prevData,
          show: false,
        }));
      }, 0);
    }
  }, [alertData.show]);

  const handleSubmit = (data, { setSubmitting, resetForm }) => {
    updateProfilePassword(
      data.oldpass,
      data.newpass,
      (response) => {
        if (response?.data?.status === "error") {
          setalertData({
            show: true,
            message: <span>{response?.data?.message}</span>,
            variant: "error",
          });
        } else if (response?.data?.status === "success") {
          setalertData({
            show: true,
            message: <span>{response?.data?.message}</span>,
            variant: "success",
          });
        } else {
          setalertData({
            show: true,
            message: "Something went wrong, please try again later",
            variant: "error",
          });
        }
        setSubmitting(false);
      },
      (error) => {
        setalertData({
          show: true,
          message: t("pages.title.An error occurred, please try again later"),
          variant: "error",
        });
        setSubmitting(false);
      }
    );
  };

  const cancelSubscription = () => {
    let params = {};
    cabcelsubscription(
      params,
      (response) => {
        if (response?.data?.status === "error") {
          setalertData({
            show: true,
            message: response?.data?.message,
            variant: "error",
          });
        } else if (response?.data?.status === "success") {
          setalertData({
            show: true,
            message: response?.data?.message,
            variant: "success",
          });

          localStorage.clear();
          setAuthToken(false);
          navigate("/login");
        } else {
          setalertData({
            show: true,
            message: "Something went wrong, please try again later",
            variant: "error",
          });
        }
      },
      (error) => {
        setalertData({
          show: true,
          message: t("pages.title.An error occurred, please try again later"),
          variant: "error",
        });
      }
    );
  };

  const convertTimestampToDate = (timestamp) => {
    const date = new Date(timestamp * 1000);
    const options = { year: "numeric", month: "long", day: "numeric" };
    const formattedDate = date.toLocaleDateString("en-US", options);
    return formattedDate;
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <div>
        {" "}
        {alertData.show ? (
          <SweetAlert alertData={alertData} setalertData={setalertData} />
        ) : (
          ""
        )}
      </div>
      <Div className="profile-password">
        <h2 className="profile-billing">{t("pages.title.updatepassword")}</h2>
        <Formik
          initialValues={{
            oldpass: "",
            newpass: "",
            confirmpassword: "",
          }}
          validationSchema={validationSchema(t)}
          onSubmit={(data, { setSubmitting, resetForm }) => {
            setSubmitting(true);
            handleSubmit(data, { setSubmitting, resetForm });
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            isSubmitting,
          }) => (
            <Form noValidate autoComplete="off">
              <Div className="prof-pass-wrap prof-pass-halfwrap">
                <Div className="prof-pass">
                  <label htmlFor="oldpass">
                    {`${t("pages.title.oldpassword")}*`}
                  </label>
                  <input
                    type="password"
                    id="oldpass"
                    name="oldpass"
                    value={values.oldpass}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder={t("pages.title.oldpassword")}
                  />
                  <span>
                    {" "}
                    {touched.oldpass && errors.oldpass ? (
                      <div className="error-message">{errors.oldpass}</div>
                    ) : (
                      ""
                    )}
                  </span>
                </Div>
              </Div>
              <Div className="prof-pass-wrap prof-pass-fullwrap">
                <Div className="prof-pass">
                  <label htmlFor="newpass">
                    {`${t("pages.title.newpassword")}*`}
                  </label>
                  <input
                    type="password"
                    id="newpass"
                    name="newpass"
                    value={values.newpass}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder={t("pages.title.newpassword")}
                  />
                  <span>
                    {touched.newpass && errors.newpass ? (
                      <div className="error-message">{errors.newpass}</div>
                    ) : (
                      ""
                    )}
                  </span>
                </Div>
                <Div className="prof-pass">
                  <label htmlFor="confirmpassword">
                    {`${t("pages.title.confirmpassword")}*`}
                  </label>
                  <input
                    type="password"
                    id="confirmpassword"
                    name="confirmpassword"
                    value={values.confirmpassword}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder={t("pages.title.newpassword")}
                  />
                  <span>
                    {touched.confirmpassword && errors.confirmpassword ? (
                      <div className="error-message">
                        {errors.confirmpassword}
                      </div>
                    ) : (
                      ""
                    )}
                  </span>
                </Div>
              </Div>
              <Div className="prof-pass prof-submit-wrap">
                <button
                  className="prof-submit"
                  type="submit"
                  disabled={isSubmitting}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <span>
                    {" "}
                    {isSubmitting ? (
                      <span>
                        <CircularProgress
                          size={24}
                          style={{ marginRight: 8 }}
                        />
                      </span>
                    ) : (
                      <span>{t("pages.title.submit")}</span>
                    )}
                  </span>
                  <span>
                    <svg
                      width="15"
                      height="8"
                      viewBox="0 0 15 8"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M9.7207 6.70283C9.6449 6.77141 9.58411 6.85411 9.54195 6.946C9.49979 7.03789 9.47712 7.13709 9.47529 7.23767C9.47346 7.33826 9.49252 7.43817 9.53131 7.53144C9.57011 7.62472 9.62786 7.70945 9.70111 7.78059C9.77437 7.85172 9.86163 7.9078 9.95768 7.94548C10.0537 7.98316 10.1566 8.00166 10.2602 7.99988C10.3638 7.99811 10.4659 7.97609 10.5606 7.93515C10.6552 7.89421 10.7404 7.83517 10.811 7.76157L14.1549 4.51543L14.7 3.98605L14.1549 3.45668L10.812 0.210533C10.6666 0.0740079 10.4718 -0.00158844 10.2695 2.51156e-05C10.0672 0.00163867 9.8737 0.0803334 9.7306 0.21916C9.5875 0.357986 9.50628 0.545836 9.50443 0.742251C9.50258 0.938665 9.58024 1.12793 9.7207 1.26928L11.747 3.23694L1.07144 3.23694C0.866845 3.23694 0.670627 3.31587 0.525957 3.45635C0.381286 3.59684 0.300011 3.78738 0.300011 3.98605C0.30001 4.18473 0.381285 4.37527 0.525957 4.51575C0.670627 4.65624 0.866845 4.73516 1.07144 4.73516L11.747 4.73516L9.7207 6.70283Z"
                        fill="white"
                      />
                    </svg>
                  </span>
                </button>
              </Div>
            </Form>
          )}
        </Formik>

        {loginUserData?.trial === "Yes" &&
        loginUserData?.trial_status === "Active" ? (
          <div className="cancel-trail-wraper">
            <span>
              <span>Your Trial Ends:</span>
              <span>{convertTimestampToDate(loginUserData?.trial_end)}</span>
            </span>
            <button onClick={handleClickOpen} className="cancel-trail-btn">
              <span> Cancel Trial</span>
            </button>
          </div>
        ) : null}
      </Div>
      <AnimatedDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle
          sx={{
            m: 0,
            p: 2,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <WarningIcon sx={{ mr: 1, color: "red", fontSize: 40 }} />

          <Typography sx={{ marginTop: 2 }} variant="h6">
            Confirmation
          </Typography>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <span>
              {" "}
              <CloseIcon />
            </span>
          </IconButton>
        </DialogTitle>
        <DialogContent style={{ width: 400, textAlign: "center" }} dividers>
          <div style={{ textAlign: "center", padding: "20px" }}>
            <Typography variant="h5" gutterBottom>
              Are you sure you want to cancel your subscription?
            </Typography>
            <Typography variant="body1" gutterBottom>
              Please note that all your data will be permanently deleted upon
              cancellation.
            </Typography>
          </div>
          <div style={{ textAlign: "center" }}>
            <Button
              variant="contained"
              sx={{ backgroundColor: "#41CE81", color: "#fff", mr: 2 }}
              onClick={cancelSubscription}
            >
              <span> Yes</span>
            </Button>
            <Button variant="contained" color="error" onClick={handleClose}>
              <span> No</span>
            </Button>
          </div>
        </DialogContent>
      </AnimatedDialog>
    </>
  );
};

export default PasswordForm;
