import React, { useEffect, useState } from "react";
import Div from "@jumbo/shared/Div";
import { Link } from "react-router-dom";
import { ASSET_IMAGES } from "../../utils/constants/paths";
import { getCompanyLogo } from "backendServices/ApiCalls";

const Logo = ({ mini, mode, sx }) => {
  const [companyData, setCompanyData] = useState(null);

  useEffect(() => {
    let isMounted = true;

    const fetchCompanyLogo = async () => {
      try {
        let domainParts;
        if (window.location.hostname === "localhost") {
          domainParts = "admin-lyriange.com".split(".");
        } else {
          domainParts = window.location.hostname.split(".");
        }

        if (domainParts && domainParts[0]) {
          let final_domain;
          if (domainParts[0].includes("-")) {
            const parts = domainParts[0].split("-");
            final_domain = parts[1];
          } else {
            final_domain = domainParts[0];
          }

          if (final_domain === "app" || final_domain === "stagingapp") {
            final_domain = "";
          }

          if (final_domain) {
            const response = await getCompanyLogo({ domain: final_domain });

            if (isMounted) {
              setCompanyData(response?.data?.data);

              const favicon = document.querySelector('link[rel="icon"]');
              if (favicon && response?.data?.data?.logo_val) {
                favicon.href = response.data?.data?.fav_icon_val;
              }

              if (response?.data?.data?.company) {
                const capitalizedCompany =
                  response?.data?.data?.company.charAt(0).toUpperCase() +
                  response?.data?.data?.company.slice(1);
                document.title = capitalizedCompany + "";
              }
            }
          }
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchCompanyLogo();

    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <Div
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        ...sx,
      }}
    >
      {/* old logo */}
      {/* <Link to={"/"}>
        {companyData ? (
          <img
            src={mode === "light" ? companyData.logo_val : companyData.logo_val}
            style={{ width: "80px" }}
            alt="Novalya"
          />
        ) :  !mini ? (
          <img
            src={
              `${ASSET_IMAGES}/logo-sm.png`
            }
            style={{ width: "80px" }}
            alt="novalya"
          />
        ) : (
          <img
            src={
            
               `${ASSET_IMAGES}/logo.png`
            }
            style={{ width: "80px" }}
            alt="novalya"
          />
        )}
      </Link> */}

      {/* new Logo */}
      <Link to={"/"}>
        {!mini ? (
          <img
            src={`${ASSET_IMAGES}/novalya-blue.png`}
            style={{ width: "80px" }}
            alt="novalya"
          />
        ) : (
          <img
            src={`${ASSET_IMAGES}/novalya-blue.png`}
            style={{ width: "80px" }}
            alt="novalya"
          />
        )}
      </Link>
    </Div>
  );
};

Logo.defaultProps = {
  mode: "light",
};

export default Logo;
