/* eslint-disable jsx-a11y/alt-text */
import { useEffect, useState } from "react";
import "./indexnew.css";
import { FirstStep } from "./step/first-step";
import { SecondStep } from "./step/second-step";
import { ThirdStep } from "./step/third-step";
import { ForthStep } from "./step/fourth-step";
import { FifthStep } from "./step/fifth-step";
import { SixthStep } from "./step/sixth-step";
import {
  fetchGroupList,
  fetchKeywordList,
  fetchMessageList,
  fetchCrmGroups,
  createSocialTarget,
  fetchSocialProfileFeature,
  fetchTargetList,
  fetchFolderList,
  getAllMessagesList,
} from "backendServices/ApiCalls";
import TabMenu from "app/pages/components/mui/TabMenu/TabMenu";
import { prospectionButtonsConfig } from "../prospection/TabData";
import { useLocation } from "react-router-dom";
import SweetAlert from "../../components/mui/Alerts/SweetAlert";
import { useJumboTheme } from "@jumbo/hooks";
import { useTranslation } from "react-i18next";
import { GetStarted } from "./GetStarted";

const stepCount = {
  step0: 0,
  step1: 1,
  step2: 2,
  step3: 3,
  step4: 4,
  step5: 5,
  step6: 6,
};
export function NewConnect() {
  const location = useLocation();
  const { theme } = useJumboTheme();
  const { t } = useTranslation();

  const segments = location.pathname.split("/");
  const last = segments[segments.length - 1];
  const lastSegment = last.charAt(0).toUpperCase() + last.slice(1);
  const [step, setStep] = useState(stepCount.step0);
  const [group, setGroup] = useState([]);
  const [messageData, setMessageData] = useState([]);
  const [keywordData, setKeywordData] = useState([]);
  const [crmGroupData, setCrmGroup] = useState([]);
  const [media, setMedia] = useState(lastSegment);
  const [socialProfile, setSocialProfile] = useState([]);
  const [folderData, setFolderData] = useState([]);
  const [storedLocale, setStoredLocale] = useState(
    localStorage.getItem("selectedLocale")
  );
  const [interval_data, setIntervalData] = useState({
    interval: null,
    norequest: null,
    search_index: null,
    stratagy: false,
  });

  const [group_data, setGroupData] = useState({
    group_id: null,
    message: null,
  });

  const [keyword_data, setKeywordsData] = useState({
    datevalue: null,
    gender: null,
    keyword: null,
    prospect: null,
    selectedinterval: null,
  });

  const [action_data, setActionData] = useState({
    action: null,
  });

  const [connectData, setConnectData] = useState({
    group_id: null,
    custom: false,
    message: null,
    interval: null,
    norequest: null,
    search_index: null,
    action: null,
    datevalue: null,
    gender: null,
    keyword: null,
    prospect: null,
    selectedinterval: null,
    stratagy: null,
  });

  const handleStateChange = (newstate) => {
    setConnectData((prevConnectData) => ({
      ...prevConnectData,
      ...Object.entries(newstate)
        .filter(
          ([_, value]) =>
            value !== null && value !== undefined && value !== "{}"
        )
        .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {}),
    }));
  };

  useEffect(() => {
    handleStateChange({
      ...interval_data,
      ...group_data,
      ...keyword_data,
      ...action_data,
    });
  }, [interval_data, group_data, keyword_data, action_data]);

  useEffect(() => {
    setTimeout(() => {
      setStoredLocale(localStorage.getItem("selectedLocale"));
    }, 300);
  }, [theme]);

  const [alertData, setAlertData] = useState({
    show: false,
    message: "",
    variant: "",
  });

  const renderStepContent = () => {
    switch (step) {
      case stepCount.step0:
        return <GetStarted folderData={folderData} />;

      case stepCount.step1:
        return (
          <FirstStep
            key={connectData?.id}
            messagesList={messageData}
            group={group}
            groupData={connectData}
            media={media}
            onChange={setGroupData}
            setAlertData={setAlertData}
            socialProfile={socialProfile?.id}
          />
        );

      case stepCount.step2:
        return (
          <SecondStep
            key={connectData?.id}
            onChange={setIntervalData}
            groupData={connectData}
            media={media}
          />
        );

      case stepCount.step3:
        return (
          <ThirdStep
            key={connectData?.id}
            keywordData={keywordData}
            onChange={setKeywordsData}
            groupData={connectData}
            media={media}
          />
        );

      case stepCount.step4:
        return (
          <ForthStep
            key={connectData?.id}
            groups={crmGroupData}
            onChange={setActionData}
            groupData={connectData}
          />
        );

      case stepCount.step5:
        return <FifthStep />;

      case stepCount.step6:
        return <SixthStep />;
      default:
        return null;
    }
  };

  const handleBackClick = () => {
    if (step > stepCount.step1) {
      setStep(step - 1);
    }
  };

  const handleNextClick = () => {
    if (typeof socialProfile?.id === "undefined") {
      setAlertData({
        show: true,
        message: `Please connect the ${media} feature to continue.`,
        variant: "error",
      });
    } else {
      if (step === stepCount.step4) {
        saveSetting();
      }
      if (step < stepCount.step5) {
        setStep(step + 1);
      }
    }
  };

  function fetchCrmGroupData() {
    let params = { media: media };
    fetchCrmGroups(
      params,
      (response) => {
        if (response?.data) setCrmGroup(response?.data ?? []);
      },
      (error) => {}
    );
  }

  function saveSetting() {
    let connectFindalData = connectData;

    if (connectData.keyword == 0) {
      connectFindalData.keyword = null;
    }
    let params = { media, connectData };

    createSocialTarget(
      params,
      (response) => {
        fetchTargetSettingData();
        // if (response?.data) setCrmGroup(response?.data ?? []);
      },
      (error) => {}
    );
  }
  function fetchTargetSettingData() {
    let params = {
      prospection_type: media === "Instagram" ? "instagram" : "facebook",
    };
    fetchTargetList(
      params,
      (response) => {
        const connectResponse = response?.data?.data[0] ?? [];
        if (response?.data) setConnectData(connectResponse);
      },
      (error) => {}
    );
  }

  function fetchSocialProfileFeatureData() {
    let params = { media };
    fetchSocialProfileFeature(
      params,
      (response) => {
        if (response?.data) setSocialProfile(response?.data?.data ?? []);
      },
      (error) => {}
    );
  }

  function fetchKeywordLists() {
    let params = { media: media };

    if (media == "Instagram") return true;
    fetchKeywordList(
      params,
      (response) => {
        if (response?.data?.data) {
          let keywordsResponse = response?.data?.data ?? [];
          keywordsResponse.push({ id: 0, name: "none" });
          const sortedArray = keywordsResponse.sort((a, b) => a.id - b.id);
          setKeywordData(sortedArray);
        }
      },
      (error) => {}
    );
  }

  function fetchFolderLists() {
    let params = { media: media };
    fetchFolderList(
      params,
      (response) => {
        if (response?.data?.data) setFolderData(response?.data?.data);
      },
      (error) => {}
    );
  }

  function fetchMessageLists() {
    let params = { type: "connect", media: media };
    getAllMessagesList(
      {},
      (response) => {
        if (response?.data) setMessageData(response?.data?.messages);
      },
      (error) => {}
    );
  }

  function fetchGroupData() {
    let params = { media: media };
    fetchGroupList(
      params,
      (response) => {
        if (response?.data?.data) setGroup(response?.data?.data);
      },
      (error) => {}
    );
  }
  useEffect(() => {
    fetchGroupData();
    fetchTargetSettingData();
    fetchMessageLists();
    fetchCrmGroupData();
    fetchKeywordLists();
    fetchSocialProfileFeatureData();
    fetchFolderLists();
  }, []);

  const englistTitle = [
    {
      title: "Easily connect with new prospects",
      url: "https://player.vimeo.com/video/924519885?badge=0&autopause=0&player_id=0&app_id=58479",
    },
    {
      title: "Easily connect with new prospects",
      url: "https://player.vimeo.com/video/924524000?badge=0&autopause=0&player_id=0&app_id=58479",
    },
    {
      title: "Easily connect with new prospects",
      url: "https://player.vimeo.com/video/924525295?badge=0&autopause=0&player_id=0&app_id=58479",
    },
    {
      title: "Easily connect with new prospects",
      url: "https://player.vimeo.com/video/924530186?badge=0&autopause=0&player_id=0&app_id=58479",
    },
    {
      title: "Easily connect with new prospects",
      url: "https://player.vimeo.com/video/924537174?badge=0&autopause=0&player_id=0&app_id=58479",
    },
  ];

  const frenchTitle = [
    {
      title: "Connectez facilement avec des nouveaux prospects",
      url: "https://player.vimeo.com/video/924655134?badge=0&autopause=0&player_id=0&app_id=58479",
    },
    {
      title: "Connectez facilement avec des nouveaux prospects",
      url: "https://player.vimeo.com/video/924655192?badge=0&autopause=0&player_id=0&app_id=58479",
    },
    {
      title: "Connectez facilement avec des nouveaux prospects",
      url: "https://player.vimeo.com/video/924655329?badge=0&autopause=0&player_id=0&app_id=58479",
    },
    {
      title: "Connectez facilement avec des nouveaux prospects",
      url: "https://player.vimeo.com/video/924668891?badge=0&autopause=0&player_id=0&app_id=58479",
    },
    {
      title: "Connectez facilement avec des nouveaux prospects",
      url: "https://player.vimeo.com/video/924669044?badge=0&autopause=0&player_id=0&app_id=58479",
    },
  ];
  const germanTitle = [
    {
      title: "Verbinden Sie sich leicht mit neuen Interessenten",
      url: "https://player.vimeo.com/video/926248468?badge=0&autopause=0&player_id=0&app_id=58479",
    },
    {
      title: "Verbinden Sie sich leicht mit neuen Interessenten",
      url: "https://player.vimeo.com/video/926250489?badge=0&autopause=0&player_id=0&app_id=58479",
    },
    {
      title: "Verbinden Sie sich leicht mit neuen Interessenten",
      url: "https://player.vimeo.com/video/926250585?badge=0&autopause=0&player_id=0&app_id=58479",
    },
    {
      title: "Verbinden Sie sich leicht mit neuen Interessenten",
      url: "https://player.vimeo.com/video/926250655?badge=0&autopause=0&player_id=0&app_id=58479",
    },
    {
      title: "Verbinden Sie sich leicht mit neuen Interessenten",
      url: "https://player.vimeo.com/video/926253427?badge=0&autopause=0&player_id=0&app_id=58479",
    },
  ];

  return (
    <div className="main-container" key={media}>
      <TabMenu buttonsConfig={prospectionButtonsConfig} />
      {alertData.show && (
        <SweetAlert alertData={alertData} setalertData={setAlertData} />
      )}
      <div>
        <div className="content-box">
          {storedLocale === "fr-FR" ? (
            <div className="title-box">
              <span className="title-21">{frenchTitle[step]?.title}</span>
              <a
                className="tutorial-btn"
                href={frenchTitle[step]?.url}
                target="_blank"
                rel="noreferrer"
                style={{ textDecoration: "none" }}
              >
                <span className="tutorial-btn-icon">
                  <span>
                    <svg
                      width="22"
                      height="16"
                      viewBox="0 0 22 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M21.0051 3.04396C20.8824 2.59062 20.6431 2.17732 20.3111 1.84522C19.979 1.51313 19.5657 1.27382 19.1123 1.15112C17.4527 0.698486 10.7731 0.698486 10.7731 0.698486C10.7731 0.698486 4.0931 0.712187 2.43343 1.16482C1.98009 1.28752 1.56679 1.52685 1.23471 1.85896C0.902623 2.19107 0.663337 2.60439 0.540677 3.05775C0.0386653 6.00666 -0.156074 10.5001 0.554462 13.3311C0.677134 13.7844 0.916426 14.1977 1.24851 14.5298C1.58059 14.8619 1.99388 15.1012 2.44722 15.2239C4.10689 15.6765 10.7867 15.6765 10.7867 15.6765C10.7867 15.6765 17.4664 15.6765 19.126 15.2239C19.5793 15.1012 19.9926 14.8619 20.3247 14.5298C20.6568 14.1977 20.8961 13.7844 21.0188 13.3311C21.5483 10.378 21.7115 5.88728 21.0051 3.04396Z"
                        fill="#FF0000"
                      ></path>
                      <path
                        d="M8.64694 11.3972L14.1882 8.18759L8.64694 4.97803V11.3972Z"
                        fill="white"
                      ></path>
                    </svg>
                  </span>
                </span>
                VOIR LA VIDEO EXPLICATIVE
              </a>
            </div>
          ) : storedLocale === "es-ES" ? (
            <div className="title-box">
              <span className="title-21">{germanTitle[step]?.title}</span>
              <a
                className="tutorial-btn"
                href={germanTitle[step]?.url}
                target="_blank"
                rel="noreferrer"
                style={{ textDecoration: "none" }}
              >
                <span className="tutorial-btn-icon">
                  <span>
                    <svg
                      width="22"
                      height="16"
                      viewBox="0 0 22 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M21.0051 3.04396C20.8824 2.59062 20.6431 2.17732 20.3111 1.84522C19.979 1.51313 19.5657 1.27382 19.1123 1.15112C17.4527 0.698486 10.7731 0.698486 10.7731 0.698486C10.7731 0.698486 4.0931 0.712187 2.43343 1.16482C1.98009 1.28752 1.56679 1.52685 1.23471 1.85896C0.902623 2.19107 0.663337 2.60439 0.540677 3.05775C0.0386653 6.00666 -0.156074 10.5001 0.554462 13.3311C0.677134 13.7844 0.916426 14.1977 1.24851 14.5298C1.58059 14.8619 1.99388 15.1012 2.44722 15.2239C4.10689 15.6765 10.7867 15.6765 10.7867 15.6765C10.7867 15.6765 17.4664 15.6765 19.126 15.2239C19.5793 15.1012 19.9926 14.8619 20.3247 14.5298C20.6568 14.1977 20.8961 13.7844 21.0188 13.3311C21.5483 10.378 21.7115 5.88728 21.0051 3.04396Z"
                        fill="#FF0000"
                      ></path>
                      <path
                        d="M8.64694 11.3972L14.1882 8.18759L8.64694 4.97803V11.3972Z"
                        fill="white"
                      ></path>
                    </svg>
                  </span>
                </span>
                Sehen Sie sich das Tutorial-Video an
              </a>
            </div>
          ) : (
            <div className="title-box">
              <span className="title-21">
                {t("pages.title.easily_connect_with")}
              </span>
              <a
                className="tutorial-btn"
                href={englistTitle[step]?.url}
                target="_blank"
                rel="noreferrer"
                style={{ textDecoration: "none" }}
              >
                <span className="tutorial-btn-icon">
                  <span>
                    <svg
                      width="22"
                      height="16"
                      viewBox="0 0 22 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M21.0051 3.04396C20.8824 2.59062 20.6431 2.17732 20.3111 1.84522C19.979 1.51313 19.5657 1.27382 19.1123 1.15112C17.4527 0.698486 10.7731 0.698486 10.7731 0.698486C10.7731 0.698486 4.0931 0.712187 2.43343 1.16482C1.98009 1.28752 1.56679 1.52685 1.23471 1.85896C0.902623 2.19107 0.663337 2.60439 0.540677 3.05775C0.0386653 6.00666 -0.156074 10.5001 0.554462 13.3311C0.677134 13.7844 0.916426 14.1977 1.24851 14.5298C1.58059 14.8619 1.99388 15.1012 2.44722 15.2239C4.10689 15.6765 10.7867 15.6765 10.7867 15.6765C10.7867 15.6765 17.4664 15.6765 19.126 15.2239C19.5793 15.1012 19.9926 14.8619 20.3247 14.5298C20.6568 14.1977 20.8961 13.7844 21.0188 13.3311C21.5483 10.378 21.7115 5.88728 21.0051 3.04396Z"
                        fill="#FF0000"
                      ></path>
                      <path
                        d="M8.64694 11.3972L14.1882 8.18759L8.64694 4.97803V11.3972Z"
                        fill="white"
                      ></path>
                    </svg>
                  </span>
                </span>
                {t("pages.title.Watch Tutorial Video")}
              </a>
            </div>
          )}

          <div className="shadow-wraper">
            <div className={`steps-bar step-done-0${step - 1}`}>
              <span className="progress-bar "></span>
              <div className="steps-item">
                <span className="steps-number">1</span>
                <span>{t("pages.title.who&what")} </span>
              </div>
              <div className="steps-item">
                <span className="steps-number">2</span>
                <span>{t("pages.title.setting")}</span>
              </div>
              <div className="steps-item">
                <span className="steps-number">3</span>
                <span>{t("pages.title.advance_targets")}</span>
              </div>
              <div className="steps-item">
                <span className="steps-number">4</span>
                <span>{t("pages.title.Action")}</span>
              </div>
            </div>
            {group.length > 0 && renderStepContent()}
          </div>
          <div className="step-btn-group">
            <button className="bordered-btn" onClick={handleBackClick}>
              {t("pages.title.back")}
            </button>
            {step < 5 ? (
              <button
                className="bordered-btn bordered-btn-bg"
                onClick={handleNextClick}
              >
                {step < 4 ? t("pages.title.next") : t("pages.title.finish")}
              </button>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
}
