import React, { useContext, useEffect, useState } from "react";
import {
  Grid,
  TextField,
  Button,
  MenuItem,
  Paper,
  Typography,
  Icon,
  Snackbar,
  Alert,
} from "@mui/material";
import VideoIcon from "@mui/icons-material/VideoLibrary";
import Review from "./review";
import { CampaignContext } from "./context/campaignContext";
import { getAllMessagesList } from "backendServices/ApiCalls";
import { useJumboTheme } from "@jumbo/hooks";
import { useTranslation } from "react-i18next";

const Message = ({ showInterval }) => {
  const { t } = useTranslation();

  const [messageData, setMessageData] = useState([]);
  const [selectedMessage, setSelectedMessage] = useState("");
  const [error, setError] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [showReview, setShowReview] = useState(false);
  const { setmessage, setMessageName, message } = useContext(CampaignContext);
  const handleMessageChange = (event) => {
    const selectedGroupName = event.target.value;
    setSelectedMessage(selectedGroupName);
    const messageName = messageData.find(
      (message) => message.id === selectedGroupName
    )?.name;
    setMessageName(messageName);
    setError("");
  };
  useEffect(() => {
    getAllMessagesList(
      {},
      (response) => {
        if (response?.data) setMessageData(response?.data?.messages);
      },
      (error) => {}
    );
    if (message) setSelectedMessage(message);
  }, []);
  const handleNextClick = () => {
    if (!selectedMessage) {
      setSnackbarMessage("Please select a message");
      setSnackbarOpen(true);
    } else {
      setShowReview(!showReview);
      setSnackbarOpen(false);

      setmessage(selectedMessage);
    }
  };
  const handleBack = () => {
    showInterval();
  };
  const showMessage = () => setShowReview(false);
  const { theme } = useJumboTheme();
  const [storedLocale, setStoredLocale] = React.useState(
    localStorage.getItem("selectedLocale")
  );

  useEffect(() => {
    setTimeout(() => {
      setStoredLocale(localStorage.getItem("selectedLocale"));
    }, 300);
  }, [theme]);
  return (
    <>
      {!showReview ? (
        <Paper elevation={3} style={{ padding: "2rem 4rem" }}>
          <Typography
            variant="h1"
            container
            sx={{ textAlign: "center" }}
            fontSize="1.6rem"
            marginBottom="1.5rem"
            color="primary"
          >
            {t("pages.title.message_label")}
          </Typography>
          <Typography
            variant="h6"
            container
            sx={{ textAlign: "center" }}
            marginBottom="1.5rem"
            color="grey"
          >
            {t("pages.title.campaign_subtitle3")}
          </Typography>
          <Grid container flexDirection="column" gap={2}>
            <Grid item xs={12}>
              <TextField
                select
                label="Message"
                value={selectedMessage}
                onChange={handleMessageChange}
                fullWidth
                sx={{ paddingTop: "1rem" }}
              >
                {messageData.map((group, index) => (
                  <MenuItem key={group.id} value={group.id}>
                    <span>
                      {group.title}
                    </span>
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            {error ? (
              <Grid
                container
                justifyContent="center"
                color="error.main"
                sx={{ marginTop: "1rem" }}
              >
                <Typography>{error}</Typography>
              </Grid>
            ) : (
              ""
            )}
            <Grid
              container
              gap=".5rem"
              justifyContent="center"
              alignItems="center"
              xs={12}
              margin="1rem 0"
            >
              <Icon color="primary">
                <VideoIcon />
              </Icon>{" "}
              <a
                href={
                  storedLocale === "fr-FR"
                    ? "https://player.vimeo.com/video/867896920?badge=0&autopause=0&player_id=0&app_id=58479"
                    : "https://player.vimeo.com/video/867930211?badge=0&autopause=0&player_id=0&app_id=58479"
                }
                target="_blank"
                rel="noreferrer"
              >
                <span>
                  {storedLocale === "fr-FR"
                    ? "VOIR LA VIDEO EXPLICATIVE"
                    : "Watch Tutorial Video"}
                </span>
              </a>
            </Grid>
            <Grid
              container
              justifyContent="space-between"
              alignItems="center"
              xs={12}
            >
              <Button
                variant="contained"
                sx={{
                  backgroundColor: "lightgrey",
                  color: "white",
                  ":hover": {
                    backgroundColor: "darkgrey",
                  },
                }}
                onClick={handleBack}
              >
                <span>{t("pages.title.back")}</span>
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={handleNextClick}
              >
                <span>{t("pages.title.review")}</span>
              </Button>
            </Grid>
            <Snackbar
              open={snackbarOpen}
              autoHideDuration={4000}
              onClose={() => setSnackbarOpen(false)}
            >
              <Alert onClose={() => setSnackbarOpen(false)} severity="error">
                <span>{snackbarMessage}</span>
              </Alert>
            </Snackbar>
          </Grid>
        </Paper>
      ) : (
        ""
      )}
      {showReview ? <Review showMessage={showMessage} /> : ""}
    </>
  );
};

export default Message;
