import TabMenu from 'app/pages/components/mui/TabMenu/TabMenu';
import React from 'react';
import { useTranslation } from "react-i18next";
import { MdOutlineDashboard, MdCurrencyExchange } from "react-icons/md";
import { SlSettings } from "react-icons/sl";
import { BiDollarCircle } from "react-icons/bi";

export default function AffiliateHeaderMenu() {
    const { t } = useTranslation();

    const AllAffiliatedCustomersButtonsConfig = [
        {
            labelKey: t("pages.title.Dashboard"),
            icon: <MdOutlineDashboard style={{ width: "24px", height: "24px" }} />,
            path: "/affiliate",
            id: "affiliate",
            socialAccount: null
        },
        {
            labelKey: t("pages.title.Affiliate Links"),
            icon: <SlSettings style={{ width: "24px", height: "24px" }} />,
            path: "/affiliate/links",
            id: "affiliate/links",
            socialAccount: null
        },
        {
            labelKey: t("pages.title.Level Commission"),
            icon: <MdCurrencyExchange style={{ width: "28px", height: "24px" }} />,
            path: "/affiliate/level-commission",
            id: "affiliate/level-commission",
            socialAccount: null
        },
        // Uncomment these when needed
        // {
        //     labelKey: t("pages.title.Pool Commission"),
        //     icon: <MdCurrencyExchange style={{ width: "28px", height: "24px" }} />,
        //     path: "/affiliate-dashboard/pool-commission",
        //     id: "affiliate-dashboard--pool-commission",
        //     socialAccount: null
        // },
        // {
        //     labelKey: t("pages.title.My Payments"),
        //     icon: <BiDollarCircle style={{ width: "28px", height: "24px" }} />,
        //     path: "/affiliate/payments",
        //     id: "affiliate/payments",
        //     socialAccount: null
        // },
        {
            labelKey: t("pages.title.Settings"),
            icon: <SlSettings style={{ width: "24px", height: "24px" }} />,
            path: "/affiliate/settings",
            id: "affiliate/settings",
            socialAccount: null
        }
    ];

    return <TabMenu buttonsConfig={AllAffiliatedCustomersButtonsConfig} />;
}
