import React, { useEffect, useState } from 'react'
import './SignUpStepOne.css'
import logo from '../../../../assets/img/novalya-logo.png'
import SweetAlert from "app/pages/components/mui/Alerts/SweetAlert"
import { useNavigate, useSearchParams } from 'react-router-dom'


const SignUpStepOne = () => {
     const [searchParams] = useSearchParams();
      const planId = searchParams.get("planId");
      let sign_details = localStorage.getItem("sign_details");
      sign_details= JSON.parse(sign_details)
      let names = sign_details && ((sign_details?.firstname || "") + " " + (sign_details?.lastname || ""))
    const [details,setDetails] = useState({fullName: names || "" , email:sign_details?.email || ""})
      const [alertData, setalertData] = useState({
            show: false,
            message: "",
            variant: "",
          });
        const navigate= useNavigate()
const handleSubmit = () => {
    
if(!details?.fullName?.trim()){


    setalertData({
        show: true,
        message: "Full Name is required",
        variant: "error",
      });

      return
}

if(!details?.email?.trim()){
    setalertData({
        show: true,
        message: "Email is required",
        variant: "error",
      });

      return
}
let names = details?.fullName?.trim()?.split(" ")
let detail = {...details,firstname:names?.[0],lastname:(names?.[1] || "")}
 detail= JSON.stringify(detail)
localStorage.setItem('sign_details',detail);
navigate(`/signup`)

}

useEffect(() => {
if(planId){
    localStorage.setItem('planId',planId)
}
}, [planId])



  return (
    <div className='SignUpStepOne'>
     {alertData.show && (
            <SweetAlert alertData={alertData} setalertData={setalertData} />
          )}
<div class="fullContainer">
		<div class="fullContainerIn">
			<img src={logo} alt='logo'/>
			<div class="fullContainerBox">
				<span> Start Your 14-Day <br/> Free Trial Now! </span>
				{/* <div class="progressWrap">
					<div class="progressBar">
						<div class="progressFill"></div>
					</div>
					<span>Create Your Account: Step 1 of 2</span>
				</div> */}
				<form class="fullContainerForm"  onSubmit={(e)=>e.preventDefault()}>
					<div class="full-form-group">
						<input id="form_name1" class="full-form-control" type="text" value={details?.fullName} required onChange={(e)=>setDetails({...details,fullName:e.target.value})}/>
						<label for="form_name1">Full Name</label>
					</div>
					<div class="full-form-group">
						<input id="form_name2" class="full-form-control" type="text" placeholder="" value={details?.email} required onChange={(e)=>setDetails({...details,email:e.target.value})}/>
						<label for="form_name2">Email Address</label>
					</div>
					<div class="full-form-group">
						<button class="full-form-btn" onClick={()=>handleSubmit()}>Continue 
							<svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M6.71743 11.7063L11.7174 6.70628C11.8085 6.61118 11.8798 6.49903 11.9274 6.37628C12.0274 6.13282 12.0274 5.85974 11.9274 5.61628C11.8798 5.49353 11.8085 5.38138 11.7174 5.28628L6.71743 0.286279C6.62419 0.19304 6.5135 0.11908 6.39168 0.0686195C6.26986 0.0181592 6.13929 -0.00781276 6.00743 -0.00781276C5.74113 -0.00781277 5.48573 0.0979752 5.29743 0.286279C5.10912 0.474583 5.00334 0.729977 5.00334 0.996279C5.00334 1.26258 5.10912 1.51798 5.29743 1.70628L8.59743 4.99628L1.00743 4.99628C0.742211 4.99628 0.487857 5.10164 0.30032 5.28917C0.112784 5.47671 0.00742792 5.73106 0.00742791 5.99628C0.0074279 6.2615 0.112784 6.51585 0.30032 6.70339C0.487857 6.89092 0.742211 6.99628 1.00743 6.99628L8.59743 6.99628L5.29743 10.2863C5.2037 10.3792 5.1293 10.4898 5.07854 10.6117C5.02777 10.7336 5.00163 10.8643 5.00163 10.9963C5.00163 11.1283 5.02777 11.259 5.07854 11.3809C5.1293 11.5027 5.2037 11.6133 5.29743 11.7063C5.39039 11.8 5.50099 11.8744 5.62285 11.9252C5.74471 11.9759 5.87542 12.0021 6.00743 12.0021C6.13944 12.0021 6.27014 11.9759 6.392 11.9252C6.51386 11.8744 6.62446 11.8 6.71743 11.7063Z" fill="#fff"/>
							</svg>
						</button>
					</div>
					<div class="full-form-text">
						<svg width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M13 7V5C13 2.2 10.8 0 8 0C5.2 0 3 2.2 3 5V7C1.3 7 0 8.3 0 10V17C0 18.7 1.3 20 3 20H13C14.7 20 16 18.7 16 17V10C16 8.3 14.7 7 13 7ZM5 5C5 3.3 6.3 2 8 2C9.7 2 11 3.3 11 5V7H5V5Z" fill="black"/>
						</svg>
						By providing us with your information you are consenting to the collection and use of your information in accordance with our <a class="full-form-anchor" target="_blank" href='https://novalya.com/en/terms-and-conditions/'>Terms and Conditions.</a>
					</div>
				</form>
			</div>
		</div>
	</div>

    </div>
  )
}

export default SignUpStepOne