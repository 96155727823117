import { Box, Dialog, DialogActions, DialogContent, MenuItem, TextField, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import { t } from 'i18next';
import React, { useContext, useEffect, useState } from 'react'
import { MessageContext } from '../extension/message-dialog/context/messageContext';
import { useTranslation } from 'react-i18next';

const SendCampaignIgModalComponent = ({
    sendCampaignModal,
    campiagnModalData,
    setCampiagnModalData,
    messageData,
    groupUsers,
    savedGroups
}) => {
    const [messageId, setMessageId] = useState(null);
    const { newCreateMessageId } = useContext(MessageContext);
    const { t } = useTranslation();
    
    useEffect(() => {
        if (newCreateMessageId !== null && newCreateMessageId !== undefined) {
            setMessageId(newCreateMessageId);
        } else {
            setMessageId(campiagnModalData?.message_id);
        }
    }, [newCreateMessageId, campiagnModalData]);

    return (
        <Dialog
            maxWidth="md"
            open={sendCampaignModal.isOpen}
            sx={{
                ".MuiDialog-container": {
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                },
                "& .MuiDialog-paper": {
                    borderRadius: 4,
                },
            }}
        >
            <DialogContent
                className="stage-popup-main"
                sx={{ padding: "0px" }}
            >
                <Box
                    className="campaign-title"
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor: "#F4F1FA",
                    }}
                >
                    <Typography
                        mt={1}
                        textAlign="center"
                        component="h5"
                        variant="h5"
                        sx={{
                            fontSize: "20px",
                            textAlign: "center",
                            flexGrow: 1,
                            color: "#2C73FF",
                        }}
                    >
                        {t("pages.title.send_a_campaign")}
                    </Typography>
                </Box>
                <Typography color="grey.600" mt={2} mb={2} variant="body2">
                    {`Send a campaign to ${campiagnModalData?.peopleCount} people`}
                </Typography>
                <Box>
                    <div>{t("pages.title.Select a message")}</div>
                    <TextField
                        select
                        // label="Message"
                        value={messageId}
                        onChange={(e) => {
                            setMessageId(e.target.value);
                            setCampiagnModalData({
                                ...campiagnModalData,
                                message_id: e.target.value,
                            });
                        }}
                        fullWidth
                        sx={{
                            mt: 1,
                            backgroundColor: "#F6F6F6",
                            mb: 2,
                            width: "500px",
                        }}
                    >
                        {messageData.map((group, index) => (
                            <MenuItem key={group.id} value={group.id}>
                                <span>
                                    {group.title}
                                </span>
                            </MenuItem>
                        ))}
                    </TextField>

                    <div>{t("pages.title.Select the time interval")}</div>
                    <ToggleButtonGroup
                        className="btn-grooups"
                        value={campiagnModalData?.time_interval}
                        exclusive
                        onChange={(e) => {
                            setCampiagnModalData({
                                ...campiagnModalData,
                                time_interval: e.target.value,
                            });
                        }}
                        sx={{ margin: ".5rem 0", mb: 2 }}
                    >
                        <ToggleButton
                            value="2-4 Min"
                            disabled={campiagnModalData?.peopleCount > 10}
                        >
                            <span>{t("pages.title.FAST")}</span>{t("pages.title.2-4 Min")}
                        </ToggleButton>
                        <ToggleButton value="4-6 Min">
                            <span>{t("pages.title.MEDIUM")}</span>{t("pages.title.4-6 Min")}
                        </ToggleButton>
                        <ToggleButton value="6-10 Min">
                            <span>{t("pages.title.SLOW")}</span>{t("pages.title.6-10 Min")}
                        </ToggleButton>
                        <ToggleButton value="10-15 Min">
                            <span>{t("pages.title.Very Slow")}</span>
                            {t("pages.title.10-15 Min")}
                        </ToggleButton>
                    </ToggleButtonGroup>
                </Box>

                <Box
                    sx={{ display: "flex", flexDirection: "column", gap: 1 }}
                >
                    <div>{t("pages.title.Select the next action")}</div>

                    <TextField
                        select
                        // label="Action"
                        value={campiagnModalData?.selectAction || false}
                        onChange={(e) => {
                            setCampiagnModalData({
                                ...campiagnModalData,
                                selectAction: e.target.value,
                            });
                            if (e.target.value === "Delete From Group") {
                                setCampiagnModalData({
                                    ...campiagnModalData,
                                    selectAction: e.target.value,
                                    moveGroupId: null,
                                    moveStageId: null,
                                });
                            }
                        }}
                        fullWidth
                        sx={{
                            mt: 1,
                            backgroundColor: "#F6F6F6",
                            mb: 2,
                        }}
                    >
                        <MenuItem value={false}>{t("pages.title.No Action")}</MenuItem>
                        <MenuItem value="Move To Stage">{t("pages.title.Move To Stage")}</MenuItem>
                        <MenuItem value="Move To Group">{t("pages.title.Move To Group")}</MenuItem>
                        <MenuItem value="Delete From Group">
                            {t("pages.title.Delete From Group")}
                        </MenuItem>
                    </TextField>
                    {campiagnModalData?.selectAction === "Move To Stage" ? (
                        <TextField
                            select
                            label="Stage"
                            value={campiagnModalData?.moveStageId}
                            onChange={(e) => {
                                setCampiagnModalData({
                                    ...campiagnModalData,
                                    moveStageId: e.target.value,
                                    moveGroupId: groupUsers?.id,
                                });
                            }}
                            fullWidth
                        >
                            {groupUsers?.stage.map((group) => (
                                <MenuItem key={group.stage_num} value={group.id}>
                                    {group.name}
                                </MenuItem>
                            ))}
                        </TextField>
                    ) : (
                        ""
                    )}
                    {campiagnModalData?.selectAction === "Move To Group" ? (
                        <>
                            <TextField
                                select
                                label="Group"
                                value={campiagnModalData?.moveGroupId}
                                onChange={(e) => {
                                    setCampiagnModalData({
                                        ...campiagnModalData,
                                        moveGroupId: e.target.value,
                                    });
                                }}
                                fullWidth
                            >
                                {savedGroups.map((group) => (
                                    <MenuItem key={group.id} value={group.id}>
                                        {group.name}
                                    </MenuItem>
                                ))}
                            </TextField>
                            {campiagnModalData?.moveGroupId && (
                                <TextField
                                    select
                                    label="Group Stage"
                                    value={campiagnModalData?.moveStageId}
                                    onChange={(e) => {
                                        setCampiagnModalData({
                                            ...campiagnModalData,
                                            moveStageId: e.target.value,
                                        });
                                    }}
                                    fullWidth
                                >
                                    {savedGroups
                                        .find(
                                            (group) =>
                                                group.id === campiagnModalData?.moveGroupId
                                        )
                                        ?.stage.sort(
                                            (a, b) => a.stage_num - b.stage_num
                                        )
                                        .map((group) => (
                                            <MenuItem
                                                key={group.stage_num}
                                                value={group.id}
                                            >
                                                {group.name}
                                            </MenuItem>
                                        ))}
                                </TextField>
                            )}
                        </>
                    ) : (
                        ""
                    )}
                </Box>
            </DialogContent>
            <DialogActions sx={{ backgroundColor: "#F4F1FA" }}>
                <button
                    attr-data={JSON.stringify(campiagnModalData)}
                    id="submit_insta_camping"
                    className="green-pop-btn"
                >
                    <span>{t("pages.title.send")}</span>
                </button>
                <button
                    onClick={sendCampaignModal.onClose}
                    className="gray-pop-btn"
                >
                    <span>{t("pages.title.cancel")}</span>
                </button>
            </DialogActions>
        </Dialog>
    )
}

export default SendCampaignIgModalComponent
